import React,{useState,useRef,useEffect} from 'react'

import "./Common.css";
import {BiUpload} from "react-icons/bi";
import { useNavigate, } from 'react-router-dom';
import axios from 'axios';
import Tickesaved from "../Modals/Ticketsaved";
import Wentwrong from "../Modals/Wentwrong";
import Loading from "../Modals/Loading"
import Moment from "react-moment";
import { useForm } from "react-hook-form";
import Photorequired from '../Modals/Photorequired';
import Forbidden from '../Modals/Forbidden';
const RaiseQuery = (props) => {
  const filePickerRef = useRef(null);
  const[modal,setopenmodal]=useState(false);
  const[modal2,setopenmodal2]=useState(false);
  const[modal3,setopenmodal3]=useState(false);
  const[modal4,setopenmodal4]=useState(false);
  const[modal5,setopenmodal5]=useState(false);
  const [img, setImg] = useState();
  const[visible,setvisible]=useState(3);
  const {register,handleSubmit,formState: { errors },reset,watch,trigger,} = useForm();
  const navigate=useNavigate();
  const [apUrl, setApUrl] = useState();
  const [mediaUrl, setMediaUrl] = useState([]);
  const [previewUrl, setPreviewUrl] = useState();
  const API_Key = process.env.REACT_APP_API_URL;
  const [file, setfile] = useState("");
  const[tickets,settickets]=useState([]);
  const [NoofElement, setNoofElement] = useState(3);
  const [btntext,setbtntext]=useState("Show More");
  const slice = tickets.slice(0, NoofElement);
  const [selected, setSelected] = useState('yes');
  const showmore = () => {
    setvisible((prevValue)=>prevValue + 3);
    
  };
  useEffect(() => {
    if (!file) {
      return;
    }
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setPreviewUrl(fileReader.result);
    };
    fileReader.readAsDataURL(file);
  }, [file]);

  
  
  const [raisequery, setraisequery] = useState({
    subject: "",
   
    desc: "",
    category: "",


  });
  const handleChange = (event) => {
    setraisequery((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
   
  };
  const handleFilePicker = () => {
    filePickerRef.current.click();
  };
  const handleImageUpload2 = (event) => {
    event.preventDefault();
    setImg(URL.createObjectURL(event.target.files[0]));
 if (event.target.files) {
      const pickedFile = event.target.files[0];
      setfile(pickedFile);
      return;
    }
  };
  const toUpperCaseFilter = (d) => {
    return d.toUpperCase();
};
 
  const onSubmit = (data) => {
    setopenmodal3(true);
    //event.preventDefault();
   

    const fd = new FormData();
    fd.append("subject", data.subject);
  
    fd.append("category", raisequery.category || "challenges");
  

    fd.append("desc", data.desc);
    
 fd.append("file",file);

  
     

fetch(`${API_Key}/postticket`, {
  method: 'POST',
  headers:{
    Accept: "application/json",
    mode: 'no-cors',
    'Access-Control-Allow-Origin':`${API_Key}`,
    //'Content-type': 'application/x-www-form-urlencoded',
    "type": "formData",
    'Access-Control-Allow-Credentials': true,
  },
  credentials:"include",
  body:fd,
})
.then((res) => {
  
  setopenmodal3(false);
  //window.location.reload(true);
  if(res.status===500){
    setopenmodal4(true)
  }else if(res.status===200){
    setopenmodal(true);
    reset(raisequery);
    window.location.reload(true)
  }else if(res.status===406){
    setopenmodal3(false);
    setopenmodal5(true);
  }
  

  setTimeout(() => {
    setopenmodal(false);
    setPreviewUrl("");
   },6000);
  const noti={message:"Your Query has been raised Successfully.We will get back to you soon !",category:"Support",querytype:"Query Submitted"}

    fetch(`${API_Key}/notification`, {
      method: 'POST',
      headers:{
        Accept: "application/json",
        mode: 'cors',
        'Access-Control-Allow-Origin':`${API_Key}`,
      'Content-type': 'application/json',
        
        'Access-Control-Allow-Credentials': true,
      },
      credentials:"include",
      body: JSON.stringify(noti),
    })
  }).catch((error) => {
   console.log(error);
   setopenmodal2(true);
   setopenmodal3(false);

   
 });
}

  const fetchtickets = async () => {
setopenmodal3(true);

        
    try{
      const res = await fetch(`${API_Key}/gettickets`,{
          method:"GET",
          headers:{
              Accept:"application/json",
              "Content-Type":"application/json"
          },
          credentials:"include"
         
      });
      const data = await res.json();
   
      settickets(data);
      
    
  
      if(res.status !== 200 ){
          const error = new Error(res.error);
          throw error;
          
      }else{
        setopenmodal3(false);
      }
      
   
  
  
  }catch(err){
    console.log(err);
    navigate("/signin");
  
  } 
  
  }

  useEffect(() => {
    fetchtickets();
  },[ ] );
  return (
    <div>
    {modal && <Tickesaved setOpenModal={setopenmodal}  />}
    {modal2 && <Wentwrong setOpenModal={setopenmodal2}  />}
    {modal3 && <Loading setOpenModal={setopenmodal3}  />}
    {modal4 && <Photorequired setOpenModal={setopenmodal4}  />}
    {modal5 && <Forbidden setOpenModal={setopenmodal5}  />}
   
    <div className='background_dashboardforunauth p-2'>
    <div className='commontopper'>
    <div className='container'>
    <div className='row justify-content-center'>
    <h1 className='commonhead'>Raise a query<div className='row justify-content-center'><hr className='commonheadhr'></hr></div></h1>
 
    
    </div>
    <div className='row justify-content-center'>
    <p className='Commonpara'>Your Next Career Move is Here! Raise a Query by generating a Ticket for courses, challenges, and labs and get Instant Reply! </p>
    </div>
    </div>
  
   
    </div>
    <div className='container '>
    <div className='row '>
    <div className='col-lg-8 col-md-7 col-12 mt-5'>
    <form onSubmit={handleSubmit(onSubmit)}>
    <div className="form-input col-lg-12">
     
       <div className="col">
       <label className='feedbacklabel'>Subject</label>
       <input
         name="subject"
         type="text"
         autoComplete='off'
         className={`form-control mb-1 ${errors.subject && "invalid"}`}
         placeholder="Enter Subject"
         onChange={handleChange}
        
         {...register("subject", { required: "Subject is Required",
         pattern:/^[a-zA-Z 0-9 ]*$/,
           message:"Please Enter  Subject",
           
         })}
         onKeyUp={() => {
          trigger("subject");
        }}
       />
       {errors.subject && (
        <small className="errormes ml-2 ">{errors.subject.message}</small>
      )} 
       </div>
       <div className="row col-lg-10 col-md-6 col-12 mt-1 mb-2 offset-md-1  ">
     
       <div className="col">
                    <input
                      type="radio"
                   
                      name="category"
                      value="Courses"
                      onChange={handleChange}
                      id="course"
                     
                    />

                    <label className="courdserad" for="course">
                      <span>COURSES</span>
                    </label>
                  </div>
                  <div className="col">
                    <input
                      type="radio"
                    
                      name="category"
                      value="challenges"
                    
                      onChange={handleChange}
                      id="challenge"
                      defaultChecked
                    />

                    <label className="courdserad" for="challenge">
                      <span>CHALLENGES</span>
                    </label>
                  </div>
                  <div className="col">
                  <input
                    type="radio"
                    onChange={handleChange}
                    name="category"
                    value="Labs"
             
                    id="challenge2"
            
                  />

                  <label className="courdserad " for="challenge2">
                    <span>LABS</span>
                  </label>
                </div>
             

      

       

       
   
 
       </div>
     
       <div className="col">
       <label className='feedbacklabel'>Message</label>
       <textarea
       name="desc"
       type="text"
       className={`form-control mb-1 ${errors.desc && "invalid"}`}
       onChange={handleChange}
       autoComplete="off"
       placeholder="Enter your thoughts here ...."
       rows="7"
       {...register("desc", { required: "Message body is Required",
       pattern:/^[a-zA-Z .,\n 0-9]*$/,
         message:"Message body is Required",
        
         
       })}
         
         onKeyUp={() => {
           trigger("desc");
         }}
       ></textarea>
       {errors.desc && (
        <small className="errormes ml-2 ">{errors.desc.message}</small>
      )} 
       </div>
      
       <div className='col'>
       <div className='row'>
     
      
       <div className='col '><label className='feedbacklabel'>Attach images:</label></div>
       <div className='col mt-4  offset-3 ' style={{float: "right"}}>     <span>{previewUrl && <img src={previewUrl} style={{height:"4rem"}} alt="author_profile" />}</span></div>
        <div className='col mt-4' style={{float: "right"}}>
   
        <input
       name="revphoto"
       ref={filePickerRef}
       style={{ display: "none" }}
       type="file"
       accept="image/png, image/jpeg,image/jpg"
       id='revphoto'
       onChange={handleImageUpload2}
       
       
     />
     
     {" "}<div>  <button className='btn useruploadimage' type="button"  onClick={handleFilePicker}><BiUpload size={17} color="white" style={{marginTop:"1.2rem"}} className='camera mr-3'/></button></div></div>
       
       </div>
       <div className='row justify-content-center'>
       <button className='btn feedbackbtn'>Submit</button>
       
       </div>
       
       </div>
       </div>
       
    </form></div>
    <div className='col-lg-4 col-md-3 col-12 mb-5'>
    <section className='ticketdiv '>
    <h2 className='commonhead2 text-center'>Tickets</h2>
    {/*{tickets && tickets.map((ticket,id)=>(<div className='row p-5'>
    <div className='col tickertresoldiv'>
    <div className='row'>
    <div className=' col' id='tickethead'>Ticket Subject</div>
    <div className='col' id="tickethead2" >10 May,2021|5.09pm</div>
    </div>
    <div className='row'>
    <p className='ticketpara p-2'> {ticket.desc}</p>
    </div>
    <div className='row '>
    <div className='col'>
    <h2 className='tickettypehead'>{ticket.category}:<span className='ml-2 tickettypehead2'>{ticket.desc.substr(0,5)}</span></h2>
    </div>
    <div className='col ml-5'>
    <h2 className='tickettypehead'>Status:<span className='ml-2 tickettypehead2'>Pending</span></h2>
    </div>
   
    </div>
    </div>
    
  </div>))}*/}
 {tickets.length ===0 ? <div><h1 className='notickethead'>No Tickets</h1></div>:tickets && [...tickets].reverse().slice(0,visible).map((ticket,id)=>(<div className='row p-5'>
 <div className='col tickertresoldiv'>
 <div className='row'>
 <div className=' col-5' id='tickethead'>{ticket.subject}</div>
 <div className='col-7' id="tickethead2" > <Moment filter={toUpperCaseFilter} format="D MMM YYYY || HH:mm a">{ticket.date}</Moment></div>
 </div>
 <div className='row'>
 <p className='ticketpara p-2'> {ticket.desc}</p>
 </div>
 <div className='row '>
<div className='col-7'>
<h2 className='tickettypehead'>Status:<span className='ml-1 tickettypehead2'>Pending</span></h2>
</div>
 <div className='col-4 ml-2'>
 <h2 className='tickettypehead'>Category:<span className='ml-1 tickettypehead2'>{ticket.category}</span></h2>
 </div>

 </div>
 </div>
 
</div>


))}

 
   
  
 <div className='row justify-content-center'><button onClick={showmore} className="btn ticketbtn">{btntext}</button></div>
    </section>
    </div></div>
    </div>
   </div>
    </div>
  )
}

export default  (RaiseQuery);