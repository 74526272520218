import React,{useState} from 'react'
import Moment from "react-moment";
import Invitefriend from '../Modals/Invitefriend';
import { BsShare } from "react-icons/bs";
import Deactivate from "../Modals/DeactivateAccount";
const toUpperCaseFilter = (d) => {
    return d.toUpperCase();
  };
 

const GeneralComponent = ({usedetail}) => {
    const [modalOpen3, setModalOpen3] = useState(false);
  
    const [modalOpen4, setModalOpen4] = useState(false);
  return (
    <>
    {modalOpen3 && <Invitefriend setOpenModal={setModalOpen3} />}
    {modalOpen4 && <Deactivate setOpenModal={setModalOpen4} />}
    <div>
         
    <div className="container">
      <div className="row p-5">
        <div className="col-lg-12 col-md-6 col-12  ">
          <div className="row justify-content-center">
            <div className="col-lg-9 col-12">
              <h2 className="setttingdiv2head">
                Account Created
              </h2>
              <p className="setttingdiv2para">
                Congratulations!Your account has been created
                successfully. Login now and enjoy hacking!!!!!
              </p>
            </div>
            <div className="col-lg-3 col-10 col-md-10 mt-3 justify-content-center">
              <div className="btn deactivatebtn3">
                <Moment
                  filter={toUpperCaseFilter}
                  format="D MMM YYYY || HH:mm a"
                >
                  {usedetail.date}
                </Moment>
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-lg-9 col-12">
              <h2 className="setttingdiv2head">
                Invite a Friend
              </h2>
              <p className="setttingdiv2para">
                Share, boost, and invite a friend to the
                preyground and test your skills! We have a lot
                waiting for you there!
              </p>
            </div>
            <div className="col-8 col-lg-3 mt-3 mb-2">
              <div
                className="settinghoverbtn "
                onClick={() => setModalOpen3(true)}
              >
                <BsShare color="white" size={22} />
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-lg-9 col-12">
              <h2 className="setttingdiv2head">
                Deactivate Account
              </h2>
              <p className="setttingdiv2para">
                Account deactivation will block your access to
                watch videos and run challenges. Are you sure you
                want to deactivate your account?{" "}
              </p>
            </div>
            <div className="col-10 col-lg-3 mt-3">
              <button
                className="btn deactivatebtn"
                onClick={() => setModalOpen4(true)}
              >
                DEACTIVATE
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </>
  )
}

export default GeneralComponent
