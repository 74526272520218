import React, { useState, useEffect } from "react";
import "./Navbar.css";
import Logo from "../../Assets/Landing page/Group 36.svg";
import { NavLink, useNavigate } from "react-router-dom";
import Loading from "../Dashboard/Modals/Loading";
import "./Navbar.css"
const Navbar2 = () => {
  const API_Key = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [loading,setLoading]=useState(true)

  const [username, setusername] = useState("");

  const fetchuser = async () => {
    console.log("Navbarsssss")
    try {
      const res = await fetch(`${API_Key}/Username`, {
        method: "GET",
        headers: {
          mode: "cors",
          "Access-Control-Allow-Origin": `${API_Key}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
        credentials: "include",
        //withCredentials: true,
      });
      const data = await res.json();
setLoading(false)
      setusername(data);

      if (res.status !== 200) {
        setLoading(false)
        const error = new Error(res.error);
        throw error;
      }
    } catch (err) {
      setLoading(false)
      console.log(err);
    }
  };
  useEffect(() => {

    fetchuser();
  }, []);
if(loading){
  return <Loading/>
}
else{
  return (
   <div className="navbar2-container">
        <NavLink
              className="navbar2-link"
              to="/"
              exact={true}
              activeClassName="homenavlink"
            >
              HOME{" "}
            </NavLink>
            <NavLink
                className="navbar2-link"
              to="/summer-training-in-cybersecurity"
              activeClassName="homenavlink"
            >
             TRAINING
            </NavLink>
            <NavLink
              className="navbar2-link"
              to="/events"
              activeClassName="homenavlink"
            >
           EVENTS
            </NavLink>

            <NavLink   to="/" id="g">
              <img className="navbar2-img" src={Logo} width="65rem" height="65rem" alt="logo" />
            </NavLink>

            <NavLink
              className="navbar2-link"
              to="/course"
              activeClassName="homenavlink"
            >
              COURSES
            </NavLink>
            <NavLink
             className="navbar2-link"
              to="/university"
              activeClassName="homenavlink"
            >
              UNIVERSITY{" "}
            </NavLink>

            <NavLink
               className="navbar2-link"
              to="/signin"
              activeClassName="homenavlink"
            >
              {username.name || "GET STARTED"}{" "}
              <span className="sr-only">(current)</span>
            </NavLink>
   </div>
  );
}

};

export default Navbar2;
