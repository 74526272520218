import React, { useState } from 'react'

const NotificationComponent = () => {
    const offers = () => {};
    const [offer, setoffer] = useState(true);
  return (
    <div>
    <div className="container">
      <div className="row p-5">
        <div className="col-lg-12 col-md-6 col-10  ">


          <div className="row mt-5">
            <div className="col-9 col-9">
              <h2 className="setttingdiv2head">
                Email Notifications
              </h2>
              <p className="setttingdiv2para">
                We will let you know about the new courses,
                challenges, rankings, and everything via email.
              </p>
            </div>
            <div
              className="col-lg-2 col-2 offset-1 "
              style={{ float: "right" }}
            >
              <label class="toggle" for="myToggle2">
                <input
                  class="toggle__input"
                  name="create_user"
                  value="true"
                  type="checkbox"
                  id="myToggle2"
                />
                <div class="toggle__fill"></div>
              </label>
            </div>
          </div>
    
          <div className="row mt-5">
            <div className="col-9 col-9">
              <h2 className="setttingdiv2head">
                Receive Offers and Updates
              </h2>
              <p className="setttingdiv2para">
                Are you looking for the most beneficial offer or
                updates in the field? Look no further, just reach
                us here!
              </p>
            </div>
            <div
              className="col-lg-2 col-2 offset-1 "
              style={{ float: "right" }}
            >
              <label class="toggle" for="myToggle4">
                <input
                  class="toggle__input"
                  name="create_user"
                  checked={offer}
                  onChange={(e) => setoffer(e.target.value)}
                  onClick={offers}
                  value={offer}
                  type="checkbox"
                  id="myToggle4"
                />
                <div class="toggle__fill"></div>
              </label>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-9 col-9">
              <h2 className="setttingdiv2head">
                Subscribe Newsletters
              </h2>
              <p className="setttingdiv2para">
                Want to stay posted with the latest insights and
                trends? Connect with us via our newsletter, and we
                will share the updates.
              </p>
            </div>
            <div
              className="col-lg-2 col-2 offset-1 "
              style={{ float: "right" }}
            >
              <label class="toggle" for="myToggle5">
                <input
                  class="toggle__input"
                  name="create_user"
                  value="true"
                  type="checkbox"
                  id="myToggle5"
                />
                <div class="toggle__fill"></div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default NotificationComponent
