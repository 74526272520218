import React from 'react'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import Errorpic from "../../Assets/login/error 4.png"
const Error= () => {
  return (
    <div>
<section className="commonbackground">
<div className='container'>
<div className='row justify-content-center '>
<div className=' col-lg-6 col-md-6 col-8 p-5 mt-5 mb-5'>
<img src={Errorpic} alt="error"/>

</div>


</div>

</div>

</section>
    <Footer/>
    </div>
  )
}

export default Error