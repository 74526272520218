import React from 'react';
import './CourseHighlights.css';
import { LuPenSquare } from "react-icons/lu";
import { PiCertificate } from "react-icons/pi";
import { BsBarChartFill } from "react-icons/bs";
import { PiNotebook } from "react-icons/pi";
import { GiBullseye } from "react-icons/gi";
import { FaAws } from "react-icons/fa";

const CourseHighlights = () => {
  return (
    <div className='summer-highlights'>
      <div className='summer-highlights-section'>
      <LuPenSquare  className='summer-highlights-icon'/>
      <p className='summer-highlights-desc'>Project Based Training</p>
      </div>
    
      <div className='summer-highlights-section'>
      <PiCertificate   className='summer-highlights-icon'/>
      <p className='summer-highlights-desc'>Certified & Industry Experienced Trainers</p>
      </div>
     
      <div className='summer-highlights-section'>
      <BsBarChartFill  className='summer-highlights-icon'/>
      <p className='summer-highlights-desc'>Beginner to Expert-Level Training</p>
      </div>
      <div className='summer-highlights-section'>
      <PiNotebook  className='summer-highlights-icon'/>
      <p className='summer-highlights-desc'>Best-In-Class Training Curriculum</p>
      </div>
      <div className='summer-highlights-section'>
      <GiBullseye  className='summer-highlights-icon'/>
      <p className='summer-highlights-desc'>Real World Hacking Challenges</p>
      </div>
      <div className='summer-highlights-section'>
      <FaAws className='summer-highlights-icon'/>
      <p className='summer-highlights-desc'>Cloud Access For Hacking Challenges.</p>
      </div>

    </div>
  )
}

export default CourseHighlights
