import React from 'react';
import './CourseHighlights.css';
import { LuPenSquare } from "react-icons/lu";
import { PiCertificate } from "react-icons/pi";
import { BsBarChartFill } from "react-icons/bs";
import { PiNotebook } from "react-icons/pi";
import { GiBullseye } from "react-icons/gi";
import { FaAws } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
import { CiCreditCard2 } from "react-icons/ci";
import { CiUser } from "react-icons/ci";
import { MdOutlineSpaceDashboard } from "react-icons/md";
import { IoPlayCircleOutline } from "react-icons/io5";
import { BiDoorOpen } from "react-icons/bi";

const CourseAccess = () => {
  return (
    <div className='background-color-how-training'>
     <p className='text-center training-access-heading '>How To Start Training</p>
   
    <div className='summer-highlights'>
       
      <div className='summer-highlights-section'>
      <CiCreditCard2   className='summer-highlights-icon2'/>
      <p className='summer-highlights-desc'>Register for Training</p>
      </div>
      <FaArrowRight className='summer-highlights-icon'/>
     
      <div className='summer-highlights-section'>
      <CiUser   className='summer-highlights-icon2'/>
      <p className='summer-highlights-desc'>Login To Hackersprey</p>
      </div>
      <FaArrowRight className='summer-highlights-icon'/>
     
      <div className='summer-highlights-section'>
      <MdOutlineSpaceDashboard  className='summer-highlights-icon2'/>
      <p className='summer-highlights-desc'>Go to Dashboard</p>
     
      </div>
      <FaArrowRight className='summer-highlights-icon'/>

      <div className='summer-highlights-section'>
      <IoPlayCircleOutline  className='summer-highlights-icon2'/>
      <p className='summer-highlights-desc'>Click on  Training Button</p>
      </div>
      <FaArrowRight className='summer-highlights-icon'/>

      <div className='summer-highlights-section'>
      <BiDoorOpen  className='summer-highlights-icon2'/>
      <p className='summer-highlights-desc'>Click on Access Training</p>
      </div>
     

    </div>
    </div>
  )
}

export default CourseAccess
