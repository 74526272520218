import Accordianpic1 from "../../Assets/Landing page/Register.webp";
import Accordianpic2 from "../../Assets/Landing page/Login (1).png";
import Accordianpic3 from "../../Assets/Landing page/VPN.png";
import Accordianpic4 from "../../Assets/Landing page/HIW Practice.png";


   const AccodianData = [
    {
      question: 'Signup and Receive Access to information',
      answer: 'Get ready to hack, Register yourself and join a self-learning community, and get trained like a pro.  ',
      image: Accordianpic1
    },
    {
      question: '   Login to Dashboard ',
      answer: 'To Get Access to the services  after signing up',
      image:Accordianpic2
    },
    {
      question: 'Connect to Vpn Server',
      answer: 'An open VPN file with an extension of .ovpn will be provided to the user through which the user can connect to Hackersprey Network.',
      image:Accordianpic3
    },
    {
       question: 'Learn and Practice on our Machines',
       answer: 'HackersPrey provides you 24x7 access to practice challenges and labs.  Access them anytime from anywhere and develop your professional skills with utmost confidence.  ',
       image:Accordianpic4
     },
    
    
    
     
  ]
  const Faqs = [
    {
      question: 'How can I increase my global ranking?',
      answer: 'Global ranking can be increased by participating in challenges. We have four-leveled challenges: easy, medium, hard, and insane. Each of these levels has unlimited attempts and certain scores associated with it. ',
    },
    {
      question: 'When will be the upcoming bug bounty program? ',
      answer: 'All the candidates who have been a part of our previous programs will be notified via portal, newsletter, and emails regarding the upcoming bug bounty programs, and about their topics and rewards. ',
    },
    
    {
       question: 'When will you start the new course?',
       answer: 'Users of hackersprey will receive a notification regarding the start of the new course in their user dashboard. However, emails will also be sent to the users who have subscribed to us. ',
     },
     {
      question: 'How can I get benefits from your Cyber Security Online Courses?',
      answer: 'Our Cyber Security Online Courses offer a self-learning platform with a strong emphasis on practical training. Unlike other platforms, our courses delve into in-depth knowledge, ensuring that each challenge in our ethical hacking online courses presents an opportunity to learn something new. You will gain proficiency in creating payloads and applying them in real-world scenarios. Our global rankings, challenges, and certification will prepare you to become an industry-ready cybersecurity professional. There are numerous takeaways from the Hackersprey platform.',
    },
    {
      question: ' Is there any student forum?',
      answer: 'We plan to have an active student forum for the benefit of students. However, we have yet to get one.',
    },
    {
      question: ' How can I cancel my active subscription?',
      answer: 'You will get access to paid content as per your plan, which can not be canceled as we do not have any refund policy. However, after completing your program, you can deactivate your account if you wish to. ',

    },





    {
      question: 'Are there any new Cyber Security Online Courses available?',
      answer: 'Yes, we recently launched two new ethical hacking online courses. They are WEBPWN, a professional web hacking course, and the StackXploit cybersecurity online course. Both courses offer in-depth knowledge and practical training to empower you in the field of cybersecurity.',

    },




    {
      question: 'What can I expect from your Cyber Security Online Courses?',
      answer: "Our Cyber Security Online Course offers comprehensive training in cybersecurity principles, practical techniques, and real-world applications. With self-paced learning, 70+ practical challenges, job-ready skills, videos, course materials, playground access for hands-on practice, 3 practice labs, certificates, global ranking, and more, you'll be equipped to excel in cybersecurity.",

    },
    {
      question: 'Is there an Ethical Hacking Online Course available?',
      answer: "Yes, we offer an Ethical Hacking Online Course where you'll learn advanced techniques in penetration testing, vulnerability assessment, and exploit development. This course prepares you to identify and mitigate security vulnerabilities ethically.",

    },
    {
      question: 'What Will I Learn in your StackXploit Cyber Security Online Course?',
      answer: "In our StackXploit Cyber Security Online Course, you'll master Assembly Language, reverse engineering, Buffer overflow, shellcoding, format strings, return-oriented programming, Sigreturn-oriented programming, Ret2csu, PIE bypass, bypassing NX, and other advanced techniques.",

    },



    {
      question: 'What is the duration of the StackXploit Cyber Security Online Course?',
      answer: "Our StackXploit Cyber Security Online Course has a minimum duration of 2 months. Participants also have the flexibility to extend the program if necessary, ensuring they can achieve their learning objectives at their own pace.",

    },
    {
      question: 'What is the time frame for completing your WEBPWN Cyber Security Online Course?',
      answer: "Our Cyber Security Online Course, WEBPWN, is structured for self-paced learning, offering flexibility in duration. You can choose to complete it within 1 to 3 months, customizing the program to fit your schedule. While all options are available, we suggest a 3-month timeframe for a more thorough and comprehensive learning experience in this cyber security course online.",

    },

    
    
    
    
     
  ]

  const Faqs2 = [
    {
      question: 'What is the WebHack program?',
      answer: 'The WebHack program is a comprehensive six-week web penetration testing program designed to enhance your skills in web pentesting. It provides access to 35+ course videos, 30 course and practice challenges, and a practice website which consists of 10 different vulnerabilities. ',
    },
    {
      question: 'What will I learn in the WebHack program? ',
      answer: 'The program covers various topics related to web pentesting, including OWASP Top 10 vulnerabilities, SQL injection, cross-site scripting (XSS), CSRF, SSRF, IDOR, and more. You will gain hands-on experience through practical challenges and learn the techniques used by ethical hackers to identify and exploit vulnerabilities.',
    },
    
    {
       question: 'Can I access the course videos and practice challenges after my subscription ends?',
       answer: 'Access to the course videos and practice challenges is only available during the subscription period, which is six weeks. After the subscription ends, there will be no access to the course materials unless you choose to extend your subscription by paying an additional fee.',
     },
     {question:"Will the solutions to the practice challenges be provided?",
     answer:"No, the solutions to the practice challenges will not be provided. The purpose of these challenges is to encourage participants to independently solve problems, fostering critical thinking and problem-solving skills. "

     },
     {
      question: 'Will I receive a participation certificate upon completion?',
      answer: 'Yes, upon completion of the WebHack program, you will receive a participation certificate. This certificate acknowledges your successful completion of the program and demonstrates your understanding of web penetration testing concepts. However, this is not the professional certification which will be awarded upon successfully completing the certification exam. ',
    },
    {
      question: 'Is there an exam associated with the program?',
      answer: 'Yes, there is a 24-hour exam that you need to appear for within one month after your subscription ends. The exam tests your knowledge and practical skills in web penetration testing. To pass the exam, you must score at least 80%.',
    },
    {
      question: 'Is the certification exam included in the program fee?',
      answer: 'Yes, one attempt of the certification exam is included in the program fee. This means that you can take the certification exam once without any additional cost. If you wish to take the exam multiple times, there may be an additional fee for each additional attempt. ',

    },
    {
      question: 'What happens if I dont,appear for the exam within one month after my subscription ends?',
      answer: 'If you fail to appear for the exam within one month after your subscription ends, you will not be eligible to appear for the certification exam. ',

    },
    {
      question: 'How can I prepare for the certification exam?',
      answer: 'To prepare for the certification exam, it is recommended to thoroughly study the course materials, complete the practice challenges, and gain hands-on experience by working on the practice website. Additionally, you can review the concepts covered in the course videos and refer to additional resources on web penetration testing.',

    },
    {
      question: 'Is it necessary to have prior knowledge of specific technologies before enrolling in the WebHack program?',
      answer: 'While not mandatory, it is highly recommended to have a basic understanding of javaScript, linux, web technologies, and networking concepts. These foundational skills will help you grasp the course materials more effectively and enable you to navigate through the challenges with confidence. However, even if you are not familiar with these technologies, the program provides comprehensive explanations and examples to help you learn and progress. ',

    },
    
    
    
    
     
  ]

  const Faqs3 = [
    {
      question: 'Is the RevEx program open to everyone? ',
      answer: ' Yes, the RevEx program is open to anyone interested in reverse engineering, regardless of their level of experience. ',
    },
    {
      question: 'How much does the RevEx program cost?  ',
      answer: 'The RevEx program is completely free of charge. There are no costs associated with participating in the challenges or obtaining the certificate.',
    },
    
    {
       question: 'Can I participate in the RevEx program if Im a beginner in reverse engineering? ',
       answer: 'Absolutely! The RevEx program is designed to cater to both beginners and experienced individuals. It offers challenges of varying difficulty levels, allowing beginners to learn and progress at their own pace.',
     },
     {
      question: 'Are there any learning videos provided with the RevEx program?',
      answer: 'No, the RevEx program does not include any learning videos. The program focuses on practical challenges and encourages participants to actively engage in hands-on reverse engineering tasks.',
    },
    {
      question: 'Will the absence of learning videos & resources hinder my ability to complete the RevEx challenges successfully?',
      answer: 'The absence of learning videos & resources does not necessarily hinder your ability to complete the challenges successfully. The RevEx program is designed to test your practical skills and problem-solving abilities. By leveraging alternative learning resources, conducting research, and actively experimenting, you can develop the necessary skills to overcome the challenges.',
    },
    {
      question: 'Can I participate in the RevEx program multiple times? ',
      answer: 'Yes, you are welcome to participate in the RevEx program multiple times to further enhance your skills and knowledge in reverse engineering. However, you will be eligible for the certificate only once.  ',

    },
    {
      question: 'Will I receive feedback on my solutions and report? ',
      answer: 'While individual feedback on solutions and reports may not be provided, the evaluation process by the expert panel ensures that deserving participants receive their certificates based on their comprehensive understanding of reverse engineering concepts and proficiency in solving the challenges.',

    },
    {
      question: 'How do I obtain the certificate from HackersPrey?  ',
      answer: 'To obtain the certificate from HackersPrey, you need to successfully solve all five challenges in the RevEx program and submit a detailed report explaining your methodologies and solutions. The report should be sent to certification@hackersprey.com.',

    },
     
    
    
    
    
     
  ]
  const Faqs4 = [
    {
      question: 'What is Webdrub?  ',
      answer: 'Webdrub is a free web pentesting program offered by HackersPrey. It provides participants with eight challenges & one web hacking lab to test and enhance their web pentesting skills. ',
    },
    {
      question: 'What is Tough Nut?   ',
      answer: 'Tough Nut is a web pentesting lab within the Webdrub program. It is a single website consisting of 10 different vulnerabilities that participants need to identify and exploit.',
    },
    
    {
       question: 'How can I participate in the Webdrub program? ',
       answer: 'To participate in the Webdrub program, you can visit the HackersPrey website and access the challenges. The program is free and open to anyone interested in web pentesting.',
     },
     {
      question: 'Is the Tough Nut challenge mandatory to obtain the Web Pentester Certification? ',
      answer: 'Yes, solving the Tough Nut lab and submitting a detailed pentesting report to certification@hackersprey.com is a requirement to be eligible for the HackersPrey certified Web Pentester Certification.',
    },
    {
      question: 'Are there any learning videos provided with the Webdrub program? ',
      answer: 'No, the Webdrub program does not provide any learning videos. Participants are encouraged to join HackersPrey WebHack program or explore external resources or books to enhance their web pentesting knowledge and skills.',
    },
    {
      question: 'Can I request learning videos or tutorials from HackersPrey for the Webdrub program?  ',
      answer: 'Currently, HackersPrey does not offer learning videos or tutorials for the Webdrub program. However, you can explore our premium programs such as WebHack and WEBPWN to learn about web pentesting. ',
      answer: 'Currently, HackersPrey does not offer learning videos or tutorials for the Webdrub program. However, you can explore our premium programs such as WebHack and WEBPWN to learn about web pentesting. ',

    },
    {
      question: 'How long does the evaluation process for the HackersPrey certified Web Pentester Certification take?   ',
      answer: 'The evaluation process duration may vary. HackersPrey will thoroughly review the pentesting report submitted for the Tough Nut challenge. The time taken to evaluate the report and issue the certification will depend on the volume of submissions and the complexity of the report.',

    },
 
    
     
    
    
    
    
     
  ]
  
  const Faqs5 = [
    {
      question: 'What Is the WEBPWN Web Hacking Course?    ',
      answer: 'Our professional web hacking course, WEBPWN, offers extensive training in web penetration testing – a crucial component of cyber security. This professional web hacking course comprises instructional videos, over 70 plus practical challenges, and three immersive hands-on labs, designed to elevate your expertise in web hacking. ',
    },
    {
      question: 'What Will I Learn in this Web Hacking Course?    ',
      answer: 'In this professional web hacking course, WEBPWN, delves into key topics within web penetration testing, addressing OWASP Top 10 vulnerabilities, SQL injection, cross-site scripting (XSS), CSRF, SSRF, IDOR, and more. This comprehensive cyber security online course ensures hands-on learning through practical challenges, equipping you with the techniques employed by ethical hackers to identify and exploit web vulnerabilities effectively.  ',
    },
    
    {
       question: 'For whom is this Web Hacking Course designed?  ',
       answer: 'This web hacking course caters to individuals keen on advancing their expertise in web security. This includes ethical hackers, cybersecurity professionals, and anyone aspiring to enhance their capabilities in web penetration testing and vulnerability assessment. Explore a dynamic learning experience with this cyber security course online  ',
     },
     {
      question: 'How long does it take to complete the WEBPWN Cyber Security Online Course?  ',
      answer: 'Our Cyber Security Online Course, WEBPWN, is designed to be self-paced, allowing flexibility in program duration. You have the option to complete it in 1 to 3 months, tailoring the experience to your schedule. While all durations are available, we recommend the 3-month option for a more in-depth and comprehensive learning journey in this online cyber security course.  ',
    },
    {
      question: 'Will solutions to the practice challenges be provided in the WEBPWN Cyber Security course?  ',
      answer: 'No, solutions to the practice challenges will not be provided. The intent behind these challenges in our cyber security course is to motivate participants to independently solve problems, fostering the development of critical thinking and problem-solving skills. ',
    },
    {
      question: 'Will I Receive a Certificate Upon Completion of this Web Hacking Course?     ',
      answer: "Yes, after finishing our web hacking course, WEBPWN, you'll get a certificate showing you completed the course and understand web hacking concepts. However, it's not the professional cyber security online certification. You can earn the professional certification after passing the 24-hour certification exam.  ",

    },
    {
      question: 'Is there an exam associated with the WEBPWN Cyber Security course?    ',
      answer: "Yes, there is a 24-hour exam that you need to appear within one month after your subscription to this cyber security course ends. The exam tests your knowledge and practical skills in web penetration testing. To pass the exam, you must score at least 70% marks. ",

    },
    {
      question: 'Is the certification exam included in the WEBPWN Web Hacking Course fee?     ',
      answer: 'Yes, one attempt for the certification exam is included in our professional web hacking course fee. This means that you can take the certification exam once without any additional cost. If you wish to take the exam multiple times, there may be an additional fee for each additional attempt.  ',

    },
    {
      question: "What happens if I don't take the exam within one month after my subscription ends?",
      answer: "If you don't take/schedule the exam within one month after completing the cyber security online course, your exam will be automatically scheduled on the 1st Saturday after subscription ends.  ",

    },
    {
      question: 'How Can I Prepare for the Certification Exam?   ',
      answer: 'To excel in the certification exam, we recommend a comprehensive approach. Thoroughly study the course materials provided in this professional web hacking course, complete the practice challenges, and gain hands-on experience by working in the practice labs. Additionally, review the concepts covered in the course videos and explore additional resources on web penetration testing available online. This will ensure well-rounded preparation for success. ',

    },
    {
      question: 'Do I need prior knowledge for the WEBPWN (Web Hacking Course)?    ',
      answer: "While not compulsory, it's recommended to have a basic familiarity with JavaScript, Linux, web technologies, and networking concepts. These skills enhance your understanding of the course content, empowering you to tackle challenges confidently. This cyber security online course includes detailed explanations and examples for easy learning and progression.",

    },



    {
      question: 'Is the WEBPWN Cyber Security Online Certification recognized globally?    ',
      answer: "Yes, our Cyber Security Online Certification holds global recognition, providing you with a credential that is valued across industries. ",

    },
    {
      question: 'What distinguishes the WEBPWN Cyber Security Online Certification from other programs?     ',
      answer: "Our Cyber Security Online Certification stands out for its comprehensive curriculum and practical approach, ensuring you gain practical skills for real-world cybersecurity problems. ",

    },
    {
      question: 'How does the WEBPWN Cyber Security Online Certification contribute to career advancement?     ',
      answer: "Completing the WEBPWN Cyber Security Online Certification showcases your expertise in web pentesting, enhancing your resume and opening doors to diverse career opportunities in the ever-growing field of cybersecurity.  ",

    },
 
    
     
    
    
    
    
     
  ]
  
  
  export  {AccodianData,Faqs2,Faqs,Faqs3,Faqs4,Faqs5};