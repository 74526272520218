import React,{useEffect} from 'react'
import "./Commonpages.css";

import Privacypic from "../../Assets/privacy policy/Lock (1).svg";
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import { Link } from 'react-router-dom';

const Termsofservice = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <div>
    
            <section className="commonbackground " >

          
          <br></br>     <br></br>
          <div className="container mt-5" id="Heading_container">
              <div className="row justify-content-center">
             
              <span className="Privacypolicy_span">Terms of Service</span>
                  <h2 className="Privacy_head">Terms of Service</h2>
                  <hr className="commonpagehr"></hr>
               
            
              </div>
        
             
          </div>
          </section>
         <section className="Privacydiv">
             <div className="container-fluid">
                 <div className="row">
                     <div className="col-lg-5 col-md-10 col-12 offset-md-1 order-2 order-lg-1 mt-5">
                    
                     <h1 className='disclaimerhead'>Disclaimer</h1>
                     <p className="privacypara"> The Terms of Use Agreement (hereinafter referred to as Agreement) between
                     Hackersprey and the User of the <Link className='static-link' to='/best-cyber-security-online-course'>Website</Link> (herein after referred to as User) is a binding
                     and final agreement. Hackersprey is a trademark (applied for) of Resec Systems Pvt.
                     Ltd. and all copyrights, trademarks and other legal rights vest with Resec Systems Pvt.
                     Ltd. By continuing to use the website the User agrees to the terms and conditions of the
                     Agreement. In case of disagreement with the terms of this Agreement, the User should
                     refrain from continuing to use this website and the services offered herein.
</p>

                     </div>
                     <div className="col-lg-5 col-md-10 col-12  justify-content-center order-1 order-lg-2 mt-5">
                     <img src={Privacypic}  className="img-fluid privacypic"  alt="Privacy_pic" />
                     </div>
                 </div>
             </div>
             <div className="container-fluid">
             <div className="row d-flex flex-column justify-content-center">
                 <div className="col-lg-10 col-md-10 col-12 offset-md-1 mt-5">
     
 <br>
            
            </br>
            <br>
            
            </br>
            <br>
            
            </br>
  
               
            <h1 className='disclaimerhead'>THE TERMS AND CONDITIONS OF USE</h1>
            <p className="privacypara"> Before visiting and/or using our webpages and services offered herein, the User is
            advised to carefully go through the following Terms and Conditions of Use. Every time
            the User makes use of the website they accept fully and without any reservation the
            terms of use contained herein and undertake to comply with the same.
</p>
<p className='privacypara'>
Registration for using the Website &amp; Forum is strictly restricted to individuals who are
either 18 years of age or older or those who possess legal consent of their parent or
guardian and are able and competent to enter into the terms, conditions, obligations,
and representations contained herein. By registering to use the Website &amp; Forum, the
User hereby undertake that they meet one of the criteria set forth above and that
Hackersprey is not responsible for any use by Users who do not meet the above
conditions.
</p>
<p className='privacypara'>In case of disagreement with the terms and conditions of use, the User must refrain
from continuing to use the services and the content of the Website &amp; Forum.</p>
<p className='privacypara'>Hackersprey declares that it may temporarily or permanently discontinue operation of
the Website &amp; Forum for any reason and at any point in time. Hackersprey reserves the
right to make modifications and updates to the Website &amp; Forum and its contents, layout
or presentation, at any time and without any prior notice. These Terms and Conditions
of Use can be reviewed and up-dated at any time and without previous warning. Thus
Users must read this document carefully before using the Website &amp; Forum.</p>
<p className='privacypara'>
Hackersprey Machines/Challenges might not be available for all the time. If the
network can get down at some point of stage then there should not be anyclaims
against us.
</p>

<h1 className='disclaimerhead'>INTELLECTUAL PROPERTY RIGHTS</h1>
<p className='privacypara'>The entire content of the Website &amp; Forum, including for example, text, graphics,
photos, digital phonograms, programs, representations source code, news, articles,
information, data, drawings, illustrations, trademarks, distinctive marks, names, logos,
product names, company names, services provided etc and in general, archives of
every kind is subject to copyright which belongs exclusively to the Hackersprey or its
content suppliers and is governed by the applicable National, European and
International provisions on Intellectual Property. Furthermore the website is made
available to Users strictly for personal (non-commercial and non-profit) use.</p>
<p className='privacypara'>That Hackersprey expressly prohibits any copying, reproduction, republication, transfer,
storage, processing, transmission, distribution, sale, issuance, execution, loading
(download), translation or modification in any manner, communication, dissemination or
any other use of the content of the Website in any manner or means, for commercial or
other purposes whatsoever, in part or in summary, without the prior written consent of
Hackersprey.</p>
<p className='privacypara'>That nothing displayed or uploaded on the Website &amp; Forum should be construed in any
way as granting, by implication, estoppel, or otherwise, any license or right to use any of
the Hackersprey’s Intellectual Property without the prior written consent of
HACKERSPREY.</p>
<p className='privacypara'>All goodwill generated monetary or otherwise from the use of any of Hackersprey’s
Intellectual Property shall inure to Hackersprey. Further Hackersprey reserves the right
to seek all or any remedies available for violation of these terms and conditions.</p>
<p className='privacypara'>Hackersprey bears no responsibility for any misuse and/or inappropriate use of the
services of the website by a User. For any damages caused, to the website, to the
Network, to another User or to any third party, the sole responsiblity is the User that
carried out the above mentioned actions.</p>
<p className='privacypara'>Any other trademarks, badges, product names, company names, graphics and logos
which are registered trademarks and intellectual property rights of third parties that
appear on this Website are the property of their respective owners and governed by
their own terms of use</p>
<p className='privacypara'>For any question relating to the reproduction rights of any part of the Website&amp; Forum,
as well as for applications for authorization regarding reproduction of content, please
contact:info@hackersprey.com.</p>
<h1 className='disclaimerhead'>LIMITATION OF LIABILITY</h1>
<p className="privacypara"> The content published on the Website &amp; Forum is made available as it stands.
HACKERSPREY does not provide any guarantee, explicit or otherwise, on the technical
quality, soundness, timeliness, commercial potential, appropriateness of its content.
</p>
<p className='privacypara'>
Hackersprey does not guarantee that the webpages, services, options and contents will
be provided without interruption, errors, or that every single error will stand corrected
and that all the questions submitted shall be so answered.
</p>
<p className='privacypara'>
HACKERSPREY further does not guarantee that the Website &amp; Forum or any other
associated websites and servers, through which the content is made available to the
Users, are without any “virus” or harmful components. The User’s entry to the “closed
network” - (“HACKERSPREY Network”) shall be undertaken with his own responsibility.
</p>
<p className='privacypara'>HACKERSPREY in no manner whatsoever bears the responsibility for any misuse
and/or inappropriate use of the services of the Website &amp; Forum by any User. For any
damages caused, either to the Website&amp; Forum, or to the Network, or to another User
or to any third Party, the sole responsibility vestswith the User that carried out the
action.</p>
<p className='privacypara'>HACKERSPREY under no circumstances, including the case of negligence, shall be
held responsible for any kind of damage, which the User of the Website &amp; Forum may
suffer.</p>
<h1 className='disclaimerhead'>LINKS </h1>
<p className='privacypara'>
Website &amp; Forum may contain links to other portals or websites not administered by
HACKERSPREY. HACKERSPREY hereby declares that it has no control over such
portals or websites which are subject to their own corresponding terms of use, and is
not responsible for their content and under no circumstances it gives rise to obligations
of any kind for anyone. Any links featuring on this Website&amp; Forum are for informative
and reference purposes only.
</p>
<p className='privacypara'>The placing of these links does not indicate the approval or acceptance of the content of
the corresponding websites by HACKERSPREY. Third party websites that may be
accessed through our WEBSITE &amp; Forum are not subject to the principles laid down
herein. HACKERSPREY does not accept any responsibility or liability, does not
guarantee their availability and bears no responsibility for any of their content and
services, neither for their protection of confidentiality practices.</p>
<p className='privacypara'>User will make use of such links at his own responsibility and for any problems, that
may arise during the visit or the use of these websites, User should address with the
corresponding website.</p>

<h1 className='disclaimerhead'>PROHIBITED ACTIVITIES</h1>
<p className='privacypara'>
Unless you have our express prior written permission, you may not use any
name, logo, tagline or other mark of ours or the HACKERSPREY Services,
or any identifier or tag generated by the HACKERSPREY Services, including
without limitation: (a) as a hypertext link to any website or other location
(except as provided for or enabled expressly by us); or (b) to imply
identification with us as an employee, contractor, agent or other similar
representative capacity. You also agree not to remove or alter any of these
items as we may have provided or enabled.
</p>
<p className='privacypara'>
Violating copyright, trademark or other intellectual property laws including by
removing any copyright, trademark or other proprietary rights notices contained
in or on the HACKERSPREY Services; or using any part of the content of the
HACKERSPREY Services for commercial purposes without obtaining a licence
from HACKERSPREY or its licensors.
</p>
<p className='privacypara'>Sharing or otherwise disclosing your password and other credentials you use to
access your account with any third party or to assign, sell, resell, sublicense,
rent, lease, time-share, distribute or otherwise transfer the rights granted to you
under this Agreement to any third party except with the prior written approval of
HACKERSPREY.</p>
<p className='privacypara'>Using the HACKERSPREY Services and its resources for any other than the
intended purpose such as “mining” bitcoins and other cryptocurrencies and
similar activities.</p>
<p className='privacypara'>Attempting to, or probe, investigate, scan, compromise, test the normal
functioning, operation, security or the vulnerability of, or gain unauthorized
access in any way to the HACKERSPREY Services or any computer systems,
networks, databases or infrastructure associated with the HACKERSPREY
Services; or bypass, circumvent or breach any security or authentication
measures or any other measures used to prevent or restrict access and/or use.</p>
<p className='privacypara'>Using any manual or automated software devices or other processes (including
    but not limited to spiders, robots, scrapers, crawlers, avatars, data mining tools or
    the like) to harvest or “scrape” or download data, information or communications
    
    from the HACKERSPREY Services or any computer systems, networks,
    databases or infrastructure associated with the HACKERSPREY Services.</p>
    <p className='privacypara'>Deciphering, decompiling, disassembling, reverse engineering or otherwise
    attempting to derive any source code or underlying ideas or algorithms of any
    part of the HACKERSPREY Services, without authorization from Hackersprey.</p>
    <p className='privacypara'>Monitoring the availability, performance, or functionality of the HACKERSPREY
    Services, to build, provide, or assist in the creation of, a competitive product or
    service which is substantially similar to the HACKERSPREY Services, or for any
    other benchmarking or competitive purposes.</p>
    <p className='privacypara'>Creating, translating or otherwise preparing any derivative works based upon the
    HACKERSPREY Services.</p>
    <p className='privacypara'>Engaging in any activities that may interfere with the ability of others to access or
    use the HACKERSPREY Services, their systems, or the Internet. ex. Flooding a
    system with communications requests so that it either cannot respond to
    legitimate traffic or responds so slowly that it becomes ineffective, or interfering
    with the proper functioning of any system, including by deliberate attempts to
    overload the system or in a way that results in excessive bandwidth usage, which
    may have a negative impact on other users.</p>
    <p className='privacypara'>Τransmitting, uploading, or otherwise sharing files or messages containing
    computer viruses or propagating worms, Trojan horses, or &quot;spyware&quot; programs
    and other harmful or malicious files, scripts, agents or programs.</p>
    <p className='privacypara'>Operating network services like open proxies, open mail relays, or open
    recursive domain name servers, forging TCP-IP packet headers, email headers,
    or any part of a message describing its origin or route or attempting to
    impersonate any of HACKERSPREY employees or representatives or any other
    person. Distributing, publishing, sending, or facilitating, encouraging, promoting
    or instructing others to send unsolicited mass e-mail or other messages,
    promotions, advertising, or solicitations (like “spam”), including commercial
    advertising and informational announcements.</p>
    <h1 className='disclaimerhead'>Hackersprey Trademark Use</h1>
    <p className='privacypara'>Except with express prior written permission, the user may not use any name,
    logo, tagline or other mark of ours or the HACKERSPREY Services, or any
    identifier or tag generated by the HACKERSPREY Services, including without
    limitation:</p>
    <p className='privacypara'>as a hypertext link to any website or other location (except as provided for or
        enabled expressly by us); or</p>
        <p className='privacypara'>to imply identification with us as an employee, contractor, agent or other
        similar representative capacity.</p>
        <p className='privacypara'>The User also agrees not to remove or alter any of these items as we may have
        provided or enabled.</p>
        <h1 className='disclaimerhead'>Refund Policy</h1>
        <p className='privacypara'>That it is expressly made clear that HACKERSPREY has no refund policy.</p>
  <br>
  
            
            </br>
            <br>
            
            </br>
            <br>
            
            </br>
            


  
                 </div>
                 </div></div>
                 <Footer/>
         </section>
        </div>
    )
}

export default Termsofservice
