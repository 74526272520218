import React,{useState,useEffect} from 'react';
import './SummerTrainingPage.css';
import { Helmet } from "react-helmet";
import SummerTrainingHeroSection from './SummerTrainingHeroSection';
import TrainingHighlights from './TrainingHighlights';
import TrainingCurriculum from './TrainingCurriculum';
import SummerTrainingModal from './SummerTrainingModal';
import { FaWhatsapp } from "react-icons/fa";
import SummerTrainingOverview from './SummerTrainingOverview';
import WebinarCourseCard from '../Webinar/WebinarCourseCard';
import StackImg from '../../Assets/webinar/stackxploit.png';
import WebpwnImg from '../../Assets/webinar/webpwn.png'
import SummerTrainingCertificate from './SummerTrainingCertificate';
import StackXploit2FAQs from '../Dyanmicpages/BinaryCurriculum2/StackXploit2FAQs';
import Footer from '../Footer/Footer';
import SummerTrainingIntro from './SummerTrainingIntro';
import CourseHighlights from './CourseHighlights';
import SummerTrainingTrainer from './SummerTrainingTrainer';
import CourseAccess from './CourseAccess';
const stackPoints=['Hands-on experience in bypassing security mechanisms found in linux binaries','Engage in self-paced learning with the StackXploit Cyber Security course','Industry-standard curriculum','Elevate your expertise in cyber security']
const webPwnPoints=['A Professional Web Hacking Course','Comprehensive coverage and practical exercises on all OWASP Top 10 vulnerabilities','70 Real World Hacking Challenges','Access To PreyGround']
const faq = [
  {
    sectionName: "What topics are covered in this summer training in cybersecurity?",
    sectionDesc:
      "Our summer training in cybersecurity covers a wide range of topics essential for understanding and mitigating cyber threats. These include network security, cryptography, ethical hacking, web application security, mobile security, and cloud security.",
  },
  {
    sectionName: "Can I participate in the summer training in cybersecurity if I have no prior experience in cybersecurity?",
    sectionDesc:
      "Absolutely! Our summer training in cybersecurity is designed to accommodate participants with varying levels of experience, including beginners. We provide comprehensive instruction and hands-on exercises to ensure all participants can grasp and build upon the fundamentals effectively.",
  },
  {
    sectionName: "Will I receive a certification upon completing the cyber security summer internship?",
    sectionDesc:
      "Yes, upon successful completion of the cyber security summer internship, participants will receive a certification. This credential serves as tangible proof of the skills and knowledge acquired during the internship and can be valuable for future career opportunities.",
  },
  {
    sectionName: "How does the summer internship in cyber security enhance my career prospects?",
    sectionDesc:
      "Participating in the summer internship in cyber security provides you with valuable hands-on experience, industry insights, and networking opportunities. Employers highly value candidates with practical experience, making them more competitive in the job market and opening doors to exciting career opportunities in cyber security. ",
  },
  {
    sectionName: " How long is this summer training in cybersecurity? ",
    sectionDesc:
      "This summer training in cybersecurity lasts for 6 weeks. This duration allows participants to examine various aspects of cybersecurity, gain practical experience, and develop essential skills needed for success in the field.",
  },
  {
    sectionName: "Is the summer internship in cyber security suitable for beginners?",
    sectionDesc:
      "Yes, our summer internship in cyber security is suitable for beginners. Our program is designed to accommodate individuals with varying levels of experience, including those who are new to the field of cybersecurity. ",
  },
  {
    sectionName: "Will I receive mentorship during the summer training in cybersecurity?",
    sectionDesc:
      "Yes, mentorship is an integral part of our training program. Participants will have access to experienced professionals who will provide guidance, support, and feedback throughout the Program of summer training in cybersecurity. Our mentors are dedicated to helping participants succeed and maximize their learning experience. ",
  },
  {
    sectionName: "How can I make the most out of this summer internship in cyber security?",
    sectionDesc:
      "To make the most out of this summer internship in cyber security, we recommend actively participating in all training sessions, asking questions, seeking feedback, and engaging with your peers and mentors. Additionally, take advantage of networking opportunities, explore different aspects of cybersecurity, and continue to learn and grow throughout the internship.",
  },
 
  
];
const SummerTrainingPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(()=>{

      setTimeout(()=>{
        setShowCallbackForm(true)
      },[5000])
  },[])
  const [showCallbackForm,setShowCallbackForm]=useState(false);
  return (
    <div className='summer-training-main-container'>
        <Helmet>
        <title>Hackersprey 6 Week Summer Training in Cyber Security  </title>
        <link
          rel="canonical"
          href="https://www.hackersprey.com/summer-training-in-cybersecurity"
        />
        <meta
          name="description"
          content="Prepare for a successful cyber security career with our 6 week summer internship in cyber security. Get Job Ready with summer training in cybersecurity." />
      
 
      <meta
          name="keyword"
          content="summer internship in cyber security, summer training in cybersecurity, cyber security summer internship, internship in cyber security" />
       
      </Helmet>
       <div className="callback-btn" onClick={()=>setShowCallbackForm(true)}>
        <p >Get a callback</p>

      </div>
      <a href="https://api.whatsapp.com/send?phone=917888995975" target="blank"><div  className="whatsapp-icon-wrapper">
  <FaWhatsapp className="whatsapp-icon"/>
  </div></a>
      {showCallbackForm ? <SummerTrainingModal onClick={()=>setShowCallbackForm(false)}/> :null}
    
      <SummerTrainingHeroSection/>
      <SummerTrainingIntro/>
      <CourseHighlights/>
      <TrainingHighlights/>
      <TrainingCurriculum/>
      <CourseAccess/>
          <SummerTrainingOverview/>
          <section className="call-to-action-section">
            <div >Have queries about our Cybersecurity Summer Training? Our counselors are here to assist you.</div>
          <p onClick={()=>setShowCallbackForm(true)}>Speak to a counsellor</p>
          </section>
          <SummerTrainingCertificate/>
          <SummerTrainingTrainer/>
          <section className="webinar-courses-card-section2 padding-top">
          <h1 className="webinar-section-heading">Start your path to success with our courses</h1>
          <div className="red-line-class">
            <hr className="binaryhr"></hr>
          </div>
         
          <div className="webinar-card-container">
            <WebinarCourseCard image={StackImg} title="StackXploit" features={stackPoints} link="/stack-xploit-cyber-security-course" />
            <WebinarCourseCard image={WebpwnImg} title="WEBPWN" features={webPwnPoints} link="/webpwn-professional-web-hacking-course"/>
          </div>
        </section>
       
        <div className='training-syl training-syl-padding'>
        <StackXploit2FAQs faq={faq}  heading="FAQ's"/>
        </div>
        <Footer/>
    </div>
   
  )
}

export default SummerTrainingPage
