import React from 'react';
import Hacker from "../../Assets/binary/sxplogo.png";
import { NavLink } from 'react-router-dom';

const Internship2 = () => {
    return (
        <div>
        <section className="Commonbackground">
        
 
    
       <div className="container mt-5   align-items-center" id="whowe">
    <div className="row justify-content-center align-items-center">
    
    
    
    <div className="col-lg-7 col-md-7 col-10 mt-3  pt-lg-0 order-2 order-lg-1   justify-content-center">
    {/*<h6 className="courseh6">HACKERS PREY CERTIFIED
      </h6>*/}
    <h3 className="courseh4">Exploiting Stacks in Linux</h3>
    <hr className="whowearehr"></hr>
    <div className='row'>
    <div className='col-lg-11'>
    <p style={{ textAlign:"justify"}} >StackXploit Program is an immersive self-paced learning experience tailored for mastering linux exploitation techniques, including stack-based methodologies. Dive deep into the intricacies of exploiting linux binaries with a focus on buffer overflow and binary exploitation. Learn to generate your own payloads and gain hands-on experience in bypassing diverse security mechanisms commonly found in linux binaries through real-world practice challenges. Embark on this journey to acquire unparalleled expertise in linux exploitation..</p>
    </div></div>
    <div className="justify-content-center mt-5 mb-5 ">
    <NavLink to="/stack-xploit-cyber-security-course"> <button className="btn coursebtn">EXPLORE</button></NavLink>
    
    </div>
    </div>
    <div className="col-lg-5 col-md-5 col-10  mt-5  mb-5 order-1 order-lg-2 " id="coursepicdiv">
    <img src={Hacker} className="img-fluid" style={{height:"auto",padding:"5rem"}} alt="Whowearepic" />
    <br></br><br></br>
    </div>
    </div>
    </div>
        </section>
    </div>
      )
    }


export default Internship2
