import * as ActionTypes from './ActionTypes';

export const addUserDetails=(userdetails)=>({
    type:ActionTypes.USER_LOGGED_IN,
    payload:userdetails
})
export const removeUserDetails=()=>({
    type:ActionTypes.USER_LOGGED_OUT
})
export const updateInstanceIP=()=>({
    type:ActionTypes.UPDATE_IP
})