import React,{useEffect,useState} from 'react'
import Forgotpass from "../../Assets/forgot pass/Group 6206.svg";
import "./Authpage.css";
import { useNavigate } from 'react-router-dom';
import Navbar from '../Navbar/Navbar';
import Footer from "../Footer/Footer"
import Loading from "../Dashboard/Modals/Loading";
import Emailnotexist from "../Dashboard/Modals/Emailnotexist";
import Otpalreadysent from '../Dashboard/Modals/Otpalreadysent';
import { useForm } from "react-hook-form";
const Forgot = () => {
  const[email,setemail]=useState("");
  const {register,handleSubmit,formState: { errors },reset,watch,trigger,} = useForm();
  const API_Key = process.env.REACT_APP_API_URL;
  const[modal,setopenmodal]=useState(false);
  const[modal2,setopenmodal2]=useState(false);
  const[modal3,setopenmodal3]=useState(false);
  const navigate=useNavigate();
  const  onSubmit = (data) => {
  
    setopenmodal(true);
 
  

fetch(`${API_Key}/verify` , {
method: 'POST',
headers: {
  Accept: 'application/json',
        mode:"cors",
                'Access-Control-Allow-Origin':`${API_Key}`, 
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': true
      },
      credentials:"include",
body: JSON.stringify(data),
})
.then((response) => { 
   
if(response.status===200){
  console.log("email is",data.email)


  navigate("/resetpass", { state: {email: data.email } });

setopenmodal(false);
}else if(response.status===400){
  setopenmodal(false);
  setopenmodal2(true);
}else if(response.status===401){
  setopenmodal3(true);
  setopenmodal(false);
}


})

.catch((error) => {
console.error('Error:', error);
//setModalOpen3(true);
});
   
            

  








}
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <div>
        {modal && <Loading setOpenModal={setopenmodal}  />}
        {modal2 && <Emailnotexist setOpenModal={setopenmodal2}  />}
        {modal3 && <Otpalreadysent setOpenModal={setopenmodal3}  />}
             <section className="authbackground">
              
        
       <div id="authpagelinediv">
           <div className="container-fluid">

           <span className="Authpagespan1">FORGOT PASSWORD</span>
               <div className="row">
                   <div className="col-lg-5 col-md-6 col-12 offset-md-2 mt-5 ">
                       <img src={Forgotpass} alt="signup"  className="img-fluid"/>
                   </div>
                   <div className="col-lg-4 col-md-4 col-12 mt-2 mt-5  mb-5 p-5">
                  
                   <div className="formdiv">
               
                       <h2 className="text-center authhead">Forgot Password</h2>
                       <div className="row justify-content-center "><hr className="signuphr1"></hr></div>
                       
<form onSubmit={handleSubmit(onSubmit)}>
                       <div class="input-group ">
                     <label>Email</label>	 
                         </div>
                        <div className="input-group_field ">
                        <input type="text" className={`mb-2 ${errors.email && "invalid"}`} name='email'  autoComplete="off" onChange={(e) => setemail(e.target.value)}  placeholder="Enter Username or Email"
                        {...register("email", { required: "Email is Required" ,
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Invalid email address",
                        }})}
                        onKeyUp={() => {
                          trigger("email");
                        }}/>
                        {errors.email && (
                          <small className="errormes ml-2">{errors.email.message}</small>
                        )}	 
                        
                           </div>
                          
                           
                      
<div className="row justify-content-center mt-5 mb-5">
  <button className="btn authbutn">SEND VERIFICATION LINK</button>
</div>
</form>
                   </div>
                   </div>
               </div>
           </div>
           </div>
         



             
         <Footer/>
          </section>
            
        </div>
    )
}

export default Forgot
