import React, { useState,useEffect } from 'react'

import {  useNavigate, NavLink} from 'react-router-dom'
import Userslider from './Userslider'
import coursesub from "../../../Assets/Dashboard/Course_1Subscriptions.svg"
import cahllenegtak from "../../../Assets/Dashboard/Challenges_1Taken.svg"
import flag from "../../../Assets/Topbar/Flag.svg";
import score from "../../../Assets/Topbar/Score Boost.svg"
import Labattend from "../../../Assets/Dashboard/Labs Attended.svg"
import { FaArrowRight } from 'react-icons/fa';
import User from "../../../Assets/images/dp3.png"
import Scores from "../../../Assets/Dashboard/fullimage.png"
import levelogo from "../../../Assets/Topbar/logo1.png"

import Webhack from "../../../Assets/Entity/Course/web.png"
import Addcourse from "../../../Assets/Dashboard/coursed.png"
  import { Line } from 'react-chartjs-2';
  import Paymentidverify from '../Modals/Paymentidverify'
  import CountUp from 'react-countup';
  
  import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
  } from 'chart.js';
  
import Profileupdate from "../../../Assets/Topbar/Profile Updated.svg"
import { color } from 'd3-color';
import { interpolateRgb } from 'd3-interpolate';
import LiquidFillGauge from 'react-liquid-gauge';
import badge1 from "../../../Assets/prodile/badge 2.png";
import Loading from "../Modals/Loading";
import { faL } from '@fortawesome/free-solid-svg-icons';
import { useCookies } from 'react-cookie'
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
  );
const Usershome = (props) => {
  const [cookie,setcookie]=useCookies();

  
  const API_Key = process.env.REACT_APP_API_URL;

  const navigate=useNavigate();
    const[user,setuser]=useState([]);
    const[rank,setrank]=useState([]);
    const[modal,setopenmodal]=useState(false);
    const[modal2,setopenmodal2]=useState(false);
    const[coursesubs,setcoursesub]=useState("");
 const[ranklevel,setranklevel]=useState("Rookie");
 const[challcount,setchallcount]=useState([])
 const[challcount2,setchallcount2]=useState([])
 const[challcount3,setchallcount3]=useState([])
 const[challcount4,setchallcount4]=useState([])
 const[challcount5,setchallcount5]=useState([])
 const[challcount6,setchallcount6]=useState([])
 const[challcount7,setchallcount7]=useState([])
 const[challcount8,setchallcount8]=useState([])
 const[challcount9,setchallcount9]=useState([])
 const[challcount10,setchallcount10]=useState([])

   const openpaymentmodal =()=>{
     setopenmodal2(true);
   }

    const fetchuserdetails = async () => {
setopenmodal(true);
      //setModalOpen3(true);
      
              
          try{
            const res = await fetch(`${API_Key}/userdata`,{
              method:"GET",
              headers:{
                mode: 'cors',
                'Access-Control-Allow-Origin':`${API_Key}`,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': true,
              },
              credentials:"include"
               
            });
            const data = await res.json([]);
        
            setuser(data);
           const cp= setcoursesub(data.courses)
         
            setchallcount2(data.ranking[0])
            setchallcount3(data.ranking[1])
            setchallcount4(data.ranking[2])
            setchallcount5(data.ranking[3])
            setchallcount6(data.ranking[4])
            setchallcount7(data.ranking[5])
            setchallcount8(data.ranking[6])
            setchallcount9(data.ranking[7])
            setchallcount10(data.ranking[8])
           
         
          
         
            if(data.status===200){
              setopenmodal(false);
              
            
            } 
         
        
            if(res.status !== 200 ){
                const error = new Error(res.error);
                throw error;
                
            }else{
              setopenmodal(false);
            
            }
            
         
        
        
        }catch(err){
          console.log(err);
          navigate("/signin");
        
        } 
        
        }
      
        useEffect(() => {
          
          fetchuserdetails();
        },[ ] );

      
        const fetchuserrankname = async () => {
          setopenmodal(true);
                //setModalOpen3(true);
                     
                    try{
                      const res = await fetch(`${API_Key}/score`,{
                        method:"GET",
                        headers:{
                          mode: 'cors',
                          'Access-Control-Allow-Origin':`${API_Key}`,
                          'Content-Type': 'application/json',
                          'Access-Control-Allow-Credentials': true,
                        },
                        credentials:"include"
                         
                      });
                      const data = await res.json([]);
                  
                      setranklevel(data.message);
              
                   
                      if(data.status===200){
              
                        setopenmodal(false);
                 
                      
                      } 
                   
                  
                      if(res.status !== 200 ){
                          const error = new Error(res.error);
                          throw error;
                          
                      }else{
                        setopenmodal(false);
                      
                      }
                      
                   
                  
                  
                  }catch(err){
                    console.log(err);
                    navigate("/signin");
                  
                  } 
                  
                  }
                
                  useEffect(() => {
                    fetchuserrankname();
                  },[ ] );


                  const  fetchchallengecount = async () => {
                    setopenmodal(true);
                          //setModalOpen3(true);
                                  
                              try{
                                const res = await fetch(`${API_Key}/challengetakenuser`,{
                                  method:"GET",
                                  headers:{
                                    mode: 'cors',
                                    'Access-Control-Allow-Origin':`${API_Key}`,
                                    'Content-Type': 'application/json',
                                    'Access-Control-Allow-Credentials': true,
                                  },
                                  credentials:"include"
                                   
                                });
                                const data = await res.json([]);
                            
                                setchallcount(data.challenge);
                              
                             
                                if(data.status===200){
                                  setopenmodal(false);
                                
                                } 
                             
                            
                                if(res.status !== 200 ){
                                    const error = new Error(res.error);
                                    throw error;
                                    
                                }else{
                                  setopenmodal(false);
                                
                                }
                                
                             
                            
                            
                            }catch(err){
                              console.log(err);
                              navigate("/signin");
                            
                            } 
                            
                            }
                          
                            useEffect(() => {
                              fetchchallengecount();
                            },[ ] );


        const fetchrank = async () => {


        
          try{
            const res = await fetch(`${API_Key}/finduserrank`,{
                method:"GET",
                headers:{
                  mode: 'cors',
                  'Access-Control-Allow-Origin':`${API_Key}`,
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Credentials': true,
                },
                credentials:"include"
               
            });
            const data = await res.json([]);
        
            setrank(data);
          
        
            if(res.status !== 200 ){
                const error = new Error(res.error);
                throw error;
                
            }
            
         
        
        
        }catch(err){
          console.log(err);
          //navigate("/signin");
        
        } 
        
        }
        useEffect(()=>{
          fetchrank();
        },[])
        
      

    const [lineData, setLineData] = useState({
        labels: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
        datasets: [
          {
            label: "Rank",
            data: [89, 100, 343, 153, 162,157, 172, 175, 156, 1000].reverse(),
            backgroundColor: "rgba(157, 196, 60, 0.75)",
            borderColor: "#D6FF55",
            borderRadius:"10px",
            borderWidth: 3,

            fill: true,
            tension: 0.5,
          },
        ],
      });
      const options = {
        plugins: { legend: { display: false } },
        elements: {
          point: {
            radius: 0,
          },
          
        },
     
        responsive: true,
        scales: { x: { display: false }, y: { display: false } },
        maintainAspectRatio: true,
        resizeDelay:3
       
      };
    
      
    
  

  

  

  const[value,setvalue]=useState(0);
 const startColor = '#e32f1c'; // cornflowerblue
  const   endColor = '#dc143c'; // crimson
    const radius = 110;
        const interpolate = interpolateRgb(startColor, endColor);
        const fillColor = interpolate(value / 100);
        const gradientStops = [
            {
                key: '0%',
                stopColor: color(fillColor).darker(0.5).toString(),
                stopOpacity: 1,
                offset: '0%'
            },
            {
                key: '50%',
                stopColor: fillColor,
                stopOpacity: 0.75,
                offset: '50%'
            },
            {
                key: '100%',
                stopColor: color(fillColor).brighter(0.5).toString(),
                stopOpacity: 0.5,
                offset: '100%'
            }
        ];
     
  return (
    <div>

    {modal && <Loading setOpenModal={setopenmodal} />}
    {modal2 && <Paymentidverify setOpenModal={setopenmodal2} />}

    <div className='background_dashboard'>
  <div className='container-fluid'>
  <div className='row justify-content-center'>
  <div className='card col-lg-5 col-md-6 col-10 p-1 mb-5' id='userhomeslide'><Userslider/></div>
  <div className='card col-lg-3 col-md-3 col-10 ml-5 ' id='scorecards'>

  <div className='row mt-0 justify-content-center'>
 <div className='levelnamediv'> <span><img  src={levelogo} style={{height:"2rem",marginRight:"1rem"}} alt="img"/></span>{ranklevel}</div>
 
 


</div>
<div className='row justify-content-center '>
<div className='col-lg-2 col mainscoredivleft'></div>
<div className='col-lg-8 col mainscorediv3'>
<div className='mainscorediv2'>
<h1><CountUp end={rank.userRank} duration={2.75} /></h1>

</div></div>
<div className='col-lg-2 col mainscoredivright'></div>







</div>
<div className='row justify-content-center'>
<div className='col-lg-6 col-md-4 col-6 justify-content-center'><span className='scorecardspan text-center'>Current Rank</span></div>



</div>
</div>
  




  <div className='card col-lg-3 col-md-3 col-10 ml-5 di2  ' id='userhomeslide'>
 
  <div className='row  justify-content-center '>
  
  <div className='col-lg-5 col'><h1 className='userrankhead'>Current Score</h1> </div>
  <div className='col-lg-4 col  offset-3 di2'>
  
  {/*<h1 className='userrankhead2'>{rank.userRank || "--"}</h1>*/}
  <h1 className='userrankhead2'>{user.total_score || "--"}</h1>
  </div>
  </div>
 
<br></br>
  <div className='row  justify-content-center' ><Line options={options} data={{
    labels: ["1", "2", "3", "4","5","6","7","8","9","10"],
    datasets: [
      {
        label: "Rank",
        
            backgroundColor: "rgba(157, 196, 60, 0.75)",
            borderColor: "#D6FF55",
            borderRadius:"5px",
            borderWidth: 3,
       Tooltip:true,
            fill: true,
            tension: 0.5,
            data:[challcount2 || "0",challcount3|| "0",challcount4|| "0",challcount5|| "0",challcount6||"0",challcount7||"0",challcount8||"0",challcount9||"0",challcount10||"0"]
      }
    ]
  }} style={{borderBottomRightRadius:"3rem",position:"bottom!important",zIndex:"2387687",borderBottomLeftRadius:"3rem",marginTop:"30%",overflow:"visible"}}/></div></div>
  
  </div>
  <div className='row justify-content-center'>
  <div className=' col-lg-5 col-md-6 col-12 p-1 '>
  <div className='row justify-content-center  '>
  

  <div className='card col-lg-3 col-md-6 col  ' id='userhomeslide2'>
  <div id='userhomeslide4'>


 <div className='row  justify-content-center'><img src={coursesub} alt="course" className='img-fluid' style={{height:"7rem",marginTop:"7rem"}}/></div>
  <div className='row justify-content-center pb-3'><h2 className='usernamechallhead'>Course Subscribed</h2></div>
    <div className='row justify-content-center pb-4 '><h1>{coursesubs}</h1></div>
    </div>
    </div>

 
  <div className='card col-lg-3 col-md-6 col' id='userhomeslide2'>
  <div id='userhomeslide5'>
  <div className='row  justify-content-center'><img src={cahllenegtak} alt="course" className='img-fluid' style={{height:"7rem",marginTop:"7rem"}}/></div>
  <div className='row justify-content-center pb-3'><h2 className='usernamechallhead'>Challenges Taken</h2></div>
  <div className='row justify-content-center pb-4'><h1>{user.total_challenges || 0}</h1></div>
  </div>
  </div>
  <div className=' card col-lg-3 col-md-6 col' id='userhomeslide2'>
  <div id='userhomeslide6'>
  <div className='row  justify-content-center'><img src={Labattend} alt="course" className='img-fluid' style={{height:"7rem",marginTop:"7rem"}}/></div>
  <div className='row justify-content-center pb-3'><h2 className='usernamechallhead'>Labs Attended</h2></div>
  <div className='row justify-content-center pb-4 '><h1>{0 || challcount}</h1></div></div>
  </div>
  </div>
  </div>
  <div className='card col-lg-3 col-md-3 col-10 ml-5' id='userhomeslide'>
  <h2 className='activityloghead'>Access Your Course</h2>
 <div className='row justify-content-center'><hr className='activityloghr'></hr></div> 

 <div className='row justify-content-center p-0'>
 <div className='col-lg-3 col-3 p-2 '><img src={Addcourse} alt="img" style={{height:"8rem",width:"8rem"}} /></div>
 <div className='col-lg-8 col-10 justify-content-center'><h6 className='activityheds text-center'>Payment ID</h6>
 <p className='activityheds2'>Continue your web pentesting journey, unlocking new levels of knowledge and expertise!</p>
<div className='row justify-content-center'>
<div className='col-lg-10 col-md-10 col'>
<button className='dashboardsubbtn' onClick={openpaymentmodal}>

<p>Enter Payment ID</p></button>
</div>
</div></div></div>


 
  </div>
  {coursesubs===1?<div className='card col-lg-3 col-md-3 col-10 ml-5' id='userhomeslide'>
 
  <h2 className='activityloghead'>Explore Your Course</h2>
 <div className='row justify-content-center'><hr className='activityloghr'></hr></div> 
 <div className='row justify-content-center p-0'>
 <div className='col-lg-3 col-3 p-2 '><img src={Webhack} alt="img" style={{height:"8rem",width:"8rem"}} /></div>
 <div className='col-lg-8 col-10 justify-content-center'><h6 className='activityheds text-center'>WEBPWN</h6>
 <p className='activityheds2'>Continue your web pentesting journey, unlocking new levels of knowledge and expertise!</p>
<div className='row justify-content-center'>
<div className='col-lg-10 col-md-10 col'>
<NavLink to="/coursedesc/64dc4e396e36a3edf9682146"><button className='dashboardsubbtn'>

<p>Dive In</p></button></NavLink>
</div>

</div> 
 </div>
 </div>
 {/*<div className='row justify-content-center p-0'>
 <div className='col-lg-2 col-2 p-2 '><img src={cahllenegtak}  style={{height:"4rem"}} alt="img" /></div>
 <div className='col-lg-8 col-8'><p className='activityheds'>Web Basics</p></div>
 </div>
 <div className='row justify-content-center p-0'>
 <div className='col-lg-2 col-2 p-2 '><img src={coursesub} alt="img" style={{height:"4rem"}} /></div>
 <div className='col-lg-8 col-8'><p className='activityheds'>Security Tools</p></div>
</div>*/}
  </div>: <div className='card col-lg-3 col-md-3 col-10 ml-5' id='userhomeslide'>
 
  <h2 className='activityloghead'>6 Weeks Summer Training</h2>
 <div className='row justify-content-center'><hr className='activityloghr'></hr></div> 
 <div className='row justify-content-center p-0'>
 
 <div className='col-lg-10 col-10'><h6 className='activityheds text-center'></h6>
 <p className='activityheds2'>Our premier 6-week summer training in cybersecurity! Gain hands-on experience in threat detection, ethical hacking, and more to kickstart your career in this dynamic field.</p>
<div className='row justify-content-center'>
<div className='col-lg-10 col-md-10 col'>
<NavLink to="/summer-training"><button className='dashboardsubbtn'>

<p>Go to Training</p></button></NavLink>
</div>

</div> 
 </div>
 </div>
 {/*<div className='row justify-content-center p-0'>
 <div className='col-lg-2 col-2 p-2 '><img src={cahllenegtak}  style={{height:"4rem"}} alt="img" /></div>
 <div className='col-lg-8 col-8'><p className='activityheds'>Web Basics</p></div>
 </div>
 <div className='row justify-content-center p-0'>
 <div className='col-lg-2 col-2 p-2 '><img src={coursesub} alt="img" style={{height:"4rem"}} /></div>
 <div className='col-lg-8 col-8'><p className='activityheds'>Security Tools</p></div>
</div>*/}
  </div>}
  </div>
  </div>

    </div>
    </div>
  )
}

export default   (Usershome)