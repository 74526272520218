import React,{useState,useEffect} from 'react'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import havocpic from "../../Assets/Entity/hackershavoc logo.png"
import{FaFlag,FaMapMarkerAlt} from "react-icons/fa"
import {AiFillCalendar,AiFillTag,AiFillTrophy} from "react-icons/ai"
import{IoPodiumSharp} from "react-icons/io5"
import{MdLocationCity} from "react-icons/md";
import{RiTeamFill} from "react-icons/ri"
import{SiHackthebox} from "react-icons/si"
import {SlTrophy} from "react-icons/sl"
import Hack from "../../Assets/Entity/Website Havoc/ctf.png"
import { NavLink } from 'react-router-dom'
import Gna from "../../Assets/Entity/Website Havoc/GNA 2.png"
import northcap from "../../Assets/Entity/Website Havoc/northcap.png"
import cu from "../../Assets/Entity/Website Havoc/cu.png"
import chitkara from "../../Assets/Entity/Website Havoc/chitkara.png"
import amity from "../../Assets/Entity/Website Havoc/amity.png";
import arpist from "../../Assets/Entity/Website Havoc/arpst.png"
import { useNavigate,useParams } from 'react-router-dom';
import moment from 'moment'
const Havoccommon = () => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const[startdate,setstartdate]=useState("")
  const gstTimezoneOffset = 4 * 60; // GST is 4 hours ahead of UTC
const[event,setevent]=useState([]);
const params=useParams()
const API_Key = process.env.REACT_APP_API_URL;
const deadline = "2024-04-03T05:21:22.399Z";
const [countdown, setCountdown] = useState({
  days: 0,
  hours: 0,
  minutes: 0,
  seconds: 0
});
const { event_id } = useParams();


useEffect(() => {
  const getEventDesc = async () => {
    try {
      const res = await fetch(`${API_Key}/getWebinar/${event_id}`, {
        method: "GET",
        headers: {
          mode: 'cors',
          'Access-Control-Allow-Origin': `${API_Key}`,
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': true,
        },
        credentials: "include"
      });
      const data = await res.json();
      setevent(data);
    } catch (err) {
      console.log(err);
    }
  };
  getEventDesc();
}, [event_id, API_Key]);

useEffect(() => {
  if (event) {
    const interval = setInterval(() => {
      const currentTime = moment();
      const deadlineTime = moment(event.startDate);
      const diff = moment.duration(deadlineTime.diff(currentTime));
      const days = diff.days();
      const hours = diff.hours();
      const minutes = diff.minutes();
      const seconds = diff.seconds();
      setCountdown({ days, hours, minutes, seconds });
    }, 1000);

    return () => clearInterval(interval);
  }
}, [event]);
 useEffect(() => {
   window.scrollTo(0, 0)
 }, [])

 
  return (
    <div>   
    <section className="Dynamicbackground2">
 

  
   
  </section>
  <div className="mainconsection">
  <div className='container'>
  <div className='row  justify-content-center'>
  <div className='col-lg-8 mt-5'>
  <div className='row justify-content-center'>
  <div className='  col-lg-3 col-md-6 col-6' >
  <div id='havoccard'>
  <div className='row justify-content-center'>
  <h1 className='havoccardhead'>{countdown.days}</h1>

  </div>
  <div className='row justify-content-center'>
  <h1 className='havoccardhead2'>Days</h1>

  </div>
  </div>
  </div>
  
  
 
  <div className='  col-lg-3 col-md-6 col-6' >
  <div id='havoccard'>
  <div className='row justify-content-center'>
  <h1 className='havoccardhead'>{countdown.hours}</h1>

  </div>
  <div className='row justify-content-center'>
  <h1 className='havoccardhead2'>Hours</h1>

  </div>
  </div>
  </div>
  <div className='  col-lg-3 col-md-6 col-6' >
  <div id='havoccard'>
  <div className='row justify-content-center'>
  <h1 className='havoccardhead'>{countdown.minutes}</h1>

  </div>
  <div className='row justify-content-center'>
  <h1 className='havoccardhead2'>Minutes</h1>

  </div>
  </div>
  </div>

  <div className='  col-lg-3 col-md-6 col-6' >
  <div id='havoccard'>
  <div className='row justify-content-center'>
  <h1 className='havoccardhead'>{countdown.seconds}</h1>

  </div>
  <div className='row justify-content-center'>
  <h1 className='havoccardhead2'>Seconds</h1>

  </div>
  </div>
  </div>
  </div>
  <div className='row justify-content-cener'>
  <div className='col-lg-4 col-md-4 col-6'>
  <div id='havoccard2'>
  <div className='row'>
  <FaFlag color='white' size={25}/>

  </div>
  <div className='row'>
  <h1 className='havochead2'>Event Type</h1>
  </div>
  <div className='row'>
  <h1 className='havocheadpara2'>{event.type==="ctf"?"Capture The Flag":"Webinar"}</h1>
  </div>
  </div>
  </div>
  <div className='col-lg-4 col-md-4 col-6'>
  <div id='havoccard2'>
  <div className='row'>
  <AiFillCalendar color='white' size={25}/>

  </div>
  <div className='row'>
  <h1 className='havochead2'>Duration</h1>
  </div>
  <div className='row'>
  <h1 className='havocheadpara2'>{event.duration} Hours</h1>
  </div>
  </div>
  </div>
  <div className='col-lg-4 col-md-4 col-6'>
  <div id='havoccard2'>
  <div className='row'>
  <FaMapMarkerAlt color='white' size={25}/>

  </div>
  <div className='row'>
  <h1 className='havochead2'>Region</h1>
  </div>
  <div className='row'>
  <h1 className='havocheadpara2'>{event.region}</h1>
  </div>
  </div>
  </div>
  <div className='col-lg-4 col-md-4 col-6'>
  <div id='havoccard2'>
  <div className='row'>
  <AiFillTag color='white' size={25}/>

  </div>
  <div className='row'>
  <h1 className='havochead2'>Entry Fee</h1>
  </div>
  <div className='row'>
  <h1 className='havocheadpara2'>Free</h1>
  </div>
  </div>
  </div>


  <div className='col-lg-4 col-md-4 col-6'>
  <div id='havoccard2'>
  <div className='row'>
  <AiFillTrophy color='white' size={25}/>

  </div>
  <div className='row'>
  <h1 className='havochead2'>Price Pool</h1>
  </div>
  <div className='row'>
  <h1 className='havocheadpara2'>{event.prizePool}</h1>
  </div>
  </div>
  </div>
  <div className='col-lg-4 col-md-4 col-6'>
  <div id='havoccard2'>
  <div className='row'>
  <RiTeamFill color='white' size={25}/>

  </div>
  <div className='row'>
  <h1 className='havochead2'>Team size</h1>
  </div>
  <div className='row'>
  <h1 className='havocheadpara2'>{event.teamSize} Players</h1>
  </div>
  </div>
  </div>
  <div className='col-lg-6 col-md-6 col-6'>
  <div id='havoccard2'>
  <div className='row'>
  <IoPodiumSharp color='white' size={25}/>

  </div>
  <div className='row'>
  <h1 className='havochead2'>Difficulty</h1>
  </div>
  <div className='row'>
  <h1 className='havocheadpara2'> Easy to Medium</h1>
  </div>
  </div>
  </div>
  
  <div className='col-lg-6 col-md-6 col-6'>
  <div id='havoccard2'>
  <div className='row'>
 
  <img src={Hack} style={{"height":"20px"}}/>

  </div>
  <div className='row'>
  <h1 className='havochead2'>CTF style</h1>
  </div>
  <div className='row'>
  <h1 className='havocheadpara2'>{event.ctfStyle}</h1>
  </div>
  </div>
  </div>
  <div className='col-lg-12 col-md-12 col-12'>
  <div id='havoccard2'>
  <div className='row'>
  <MdLocationCity color='white' size={25}/>

  </div>
  <div className='row'>
  <h1 className='havochead2'>Venue</h1>
  </div>
  <div className='row'>
  <h1 className='havocheadpara2'>{event.venue}</h1>
  </div>
  </div>
  </div>
  </div>
  <div className='row mt-5 p-5'>
  <h1 className='havocheadabout'>about the event</h1>
  <div className='row p-4'>
  <h1 className='havocheadabout2'>Calling all aspiring cybersecurity enthusiasts and college students!</h1>
  </div>
  <div className='row p-4'>
  <h1 className='havocheadaboutpara'>🚀 Step Into The Realm Of Hacking Excellence Hack Master Series," The Ultimate CTF Showdown Hosted By {event.hostuni} in association with HackersPrey! 🚀</h1><br></br>
  <h1 className='havocheadaboutpara'> 

  🕵️‍♀️ Unleash Your Hacking Skills As You Dive Into An Electrifying Challenge, Exploring One Website Armed With 10 Vulnerabilities! 🕵️‍♂️ </h1><br></br>
  <h1 className='havocheadaboutpara'>⏳ Test your mettle in this 5-hour adrenaline-packed event, where you and your team will face mind-bending puzzles and intricate security barriers! ⏳</h1><br></br>
  
  
  <h1 className='havocheadaboutpara'>🎓 🎓 This Extraordinary CTF Is Exclusively Crafted For College Students Like You, Providing The Perfect Platform To Showcase Your Talents And Knowledge In Cybersecurity! 🎓 
  </h1><br></br>
 {/* <h1 className='havocheadaboutpara'>🏆 The stakes are high, and the rewards are even higher! Win the grand prize of Rs 15,000/- as the first winning team, Rs 10,000/- for the second, and Rs 5,000/- for the third. 🏆</h1><br></br> */}
  <h1 className='havocheadaboutpara'>🚀 With a maximum of 5 players in a team, rally your best minds together and conquer the challenges that lie ahead! 🚀</h1><br></br>
  <h1 className='havocheadaboutpara'>📈 Keep your eyes on the live leaderboard to witness your rankings climb as you inch closer to victory! 📈</h1><br></br>
  <h1 className='havocheadaboutpara'>So, Gather Your Teammates And Mark Your Calendars For "Hack Master Series" - The Battleground Of Brilliance And The Proving Ground For Future Cybersecurity Champions! Join Us And Embark On A Journey Of A Lifetime, Where Greatness Awaits Those Who Dare To Dominate The World Of Hacking! Are You Ready To Unleash The Hacker Within? 💻💪 </h1><br></br>
  
  
  </div>
 
  </div>
  {/*<div className='row mt-5 p-5'>
  <h1 className='havocheadabout'>why join</h1>
  <div className='row p-4'>
  <h1 className='havocheadabout2'>TL;DR: For the glory, the training, the prizes, and the certification.</h1>
  </div>
  <div className='row p-4'>
 
  <h1 className='havocheadaboutpara4'>Top-notch hacking content</h1>
  <p className='havocheadaboutpara5'>Access exclusive content featuring only the latest attacks and real-world hacking techniques.</p>
 
 
  </div>
  <div className='row p-4'>
 
  <h1 className='havocheadaboutpara4'>Top-notch hacking content</h1>
  <p className='havocheadaboutpara5'>Access exclusive content featuring only the latest attacks and real-world hacking techniques.</p>
 
 
  </div>
  <div className='row p-4'>
 
  <h1 className='havocheadaboutpara4'>Gamified upskilling</h1>
  <p className='havocheadaboutpara5'>Forget static experiences. Be part of an interactive storyline and learn while hacking.</p>
 
 
  </div>
  <div className='row p-4'>
 
  <h1 className='havocheadaboutpara4'>Free training</h1>
  <p className='havocheadaboutpara5'>Bring your team together to train and hack at the same time. Simple as that!</p>
 
 
  </div>
  <div className='row p-4'>
 
  <h1 className='havocheadaboutpara4'>Certify your attendance</h1>
  <p className='havocheadaboutpara5'>Reserve your spot, climb the charts, brag to your friends, and get CPEs and certificates.*</p>
 
 
  </div>
  <div className='row p-4'>
 
  <h1 className='havocheadaboutpara4'>Your prizes await</h1>
  <p className='havocheadaboutpara5'>Get the chance to win the Secret HTB Trophy, swag, advanced services, our hearts, and much more.</p>
 
 
  </div>
 
 
 
  </div>*/}
 
  <div className='row mt-5 p-5'>
  <h1 className='havocheadabout'>Timeline</h1>
  <div className='row p-4'>
  
  </div>
  <div className='row mt-2 p-5'>
  <div class="timeline">
  <div class="outer">
    <div class="cards" id='timelinecard'>
      <div class="info">
        <h3 class="titletimeline">Registration opens</h3>
        <p > {moment(event.registrationopens).format('MMMM DD, YYYY')} </p>
      </div>
    </div>
    
    <div class="cards" id='timelinecard'>
      <div class="info">
        <h3 class="titletimeline">CTF starts</h3>
        <p>{moment(event.startDate).format('MMMM DD, YYYY')}</p>
      </div>
    </div>
    <div class="cards" id='timelinecard'>
      <div class="info">
        <h3 class="titletimeline">CTF ends
        </h3>
        <p>{moment(event.endDate).format('MMMM DD, YYYY')} </p>
      </div>
    </div>
    <div class="cards" id='timelinecard'>
      <div class="info">
        <h3 class="titletimeline">After CTF</h3>
        <p>The much-awaited moment is here! Get ready to witness the unveiling of our extraordinary winners and witness the thrill of prizes being claimed! 🎉🏅 </p>
      </div>
    </div>
  </div>
</div>
  </div>
  </div>
 {/*} <div className='row mt-5 p-5'>
  <h1 className='havocheadabout'>MEET THE AWARDS</h1>
  <div className='row p-4'>
  <h1 className='havocheadabout2'>The stakes are high, and the rewards are even higher!</h1>
  </div>
  </div>
  <div className='row mt-2 p-5 justify-content-center'>
  <div className='col-lg-12 col-md-10 col' >
  <div id='pricedivinf'>
  <div className='priceupperdiv1 p-4'>
  <div className='row'>
  <div className='col-lg-2 col'>
  <div class="ball"><SlTrophy size={50}/></div>
  </div>
  <div className='col-lg-5 col-5'>
  <h1 className='priceheading1'>1st Prize</h1>
  </div>
  <div className='col-lg-2 col'>
 
  </div>
  <div className='col-lg-3 col'>
  <h1 className='priceheading1'>&#x20b9; 15000</h1>
  </div>
  </div>

  </div>
  <div className='row pl-5 mt-4 '>
 <div className='col-lg-12 col-md-8 col pl-5'>
 <ul className='pl-5' >
 <li style={{listStyleType:"disc"}} className="pricedivlist ">Team will receive the prize money of &#x20b9;15000 </li>
 <li style={{listStyleType:"disc"}} className="pricedivlist ">In addition to the prize money, each member of the winning team will be awarded a certification of achievement to recognize their exceptional performance</li>

 </ul>
 </div>
 
  </div>
</div>
  </div>
  </div>

  <div className='row mt-2 p-5 justify-content-center'>
  <div className='col-lg-12 col-md-10 col' >
  <div id='pricedivinf'>
  <div className='priceupperdiv2 p-4'>
  <div className='row'>
  <div className='col-lg-2 col'>
  <div className='col-lg-2 col'>
  <div class="ball"><SlTrophy size={50}/></div>
  </div>
  </div>
  <div className='col-lg-5 col-5'>
  <h1 className='priceheading1'>2nd Prize</h1>
  </div>
  <div className='col-lg-2 col'>
 
  </div>
  <div className='col-lg-3 col'>
  <h1 className='priceheading1'>&#x20b9; 10000</h1>
  </div>
  </div>

  </div>
  <div className='row pl-5 mt-4 '>
 <div className='col-lg-12 col-md-8 col pl-5'>
 <ul className='pl-5' >
 <li style={{listStyleType:"disc"}} className="pricedivlist ">Team will receive the prize money of &#x20b9;10000 </li>
 <li style={{listStyleType:"disc"}} className="pricedivlist ">In addition to the prize money, each member of the winning team will be awarded a certification of achievement to recognize their exceptional performance</li>

 </ul>
 </div>
 
  </div>
</div>
  </div>
  </div>

  <div className='row mt-2 p-5 justify-content-center'>
  <div className='col-lg-12 col-md-10 col' >
  <div id='pricedivinf'>
  <div className='priceupperdiv3 p-4'>
  <div className='row'>
  <div className='col-lg-2 col'>
  <div className='col-lg-2 col'>
  <div class="ball"><SlTrophy size={50}/></div>
  </div>
  </div>
  <div className='col-lg-5 col-5'>
  <h1 className='priceheading1'>3rd Prize</h1>
  </div>
  <div className='col-lg-2 col'>
 
  </div>
  <div className='col-lg-3 col'>
  <h1 className='priceheading1'>&#x20b9; 5000</h1>
  </div>
  </div>

  </div>
  <div className='row pl-5 mt-4 '>
 <div className='col-lg-12 col-md-8 col pl-5'>
 <ul className='pl-5' >
 <li style={{listStyleType:"disc"}} className="pricedivlist ">Team will receive the prize money of &#x20b9;5000 </li>
 <li style={{listStyleType:"disc"}} className="pricedivlist ">In addition to the prize money, each member of the winning team will be awarded a certification of achievement to recognize their exceptional performance</li>
 </ul>
 </div>
 
  </div>
</div>
  </div>
</div>*/}
  {/*<div className='row mt-2 p-5'>
  <h1 className='havocheadabout'>OUR ACADEMIC PARTNERS</h1>
  <div className='row mt-2 p-5 justify-content-center'>
  <div className='col-lg-4c col-4 mt-5'>
  <img src={cu} alt="cu"  style={{marginTop:"5rem"}}  />
  </div>
  <div className='col-lg-4  col-4 mt-5 p-5'>
  <img src={Gna} alt="gna"   style={{marginTop:"5rem"}} />
  </div>
  <div className='col-lg-4 col-4 mt-5'>
  <img src={chitkara} alt="chitkara"   style={{marginTop:"5rem"}} />
  </div>
 
  
  </div>
  <div className='row mt-2 p-5'>
  <div className='col-lg-4 mt-0'>
  <img src={northcap} alt="northcap"    />
  </div>
  <div className='col-lg-3 mt-0 ml-5'>
  <img src={amity} alt="amity"    />
  </div>
  <div className='col-lg-3 col-md-3 col ml-5'>
  <img src={arpist}  alt="arpst" />
  </div>
  </div>
 
</div>*/}
  <div className='row mt-2 p-5 justify-content-center'>
  <div className='col-lg-12 col-md-10 col' >
  

  </div>
  </div>
  
 
 
  
  </div>


  <div className='col-lg-4 col-10 mt-5'>
  <div className='row justify-content-center mt-2' id='havocformdiv'>
  <div className='col-lg-12 col-md-12 col'>
  <h1 className='havocrightsidecardhead'>{event.title}</h1>
  {/*<h2 className='havocrightsidecardhead2'></h2> */}
  <h1 className='havocrightsidecardhead3'> {moment(event.startDate).format('Do MMMM, YYYY')}</h1>
  <p className='havocheadpara3'><b>Hackersprey presents a nationwide cybersecurity adventure: The Online Hack Master Series.</b> This exclusive series comprises state-level Capture The Flag (CTF) events, leading up to the prestigious Grand CTF event scheduled for June 2024 in Chandigarh.</p>
  <h2 className='startdatehead'>Start Date</h2>
  <p className='startdatepara'>{moment(event.startDate).format('dddd, MMMM DD, hh:mm A')}</p>
  <h2 className='startdatehead mt-5'>END DATE </h2>
  <p className='startdatepara'>  {moment(event.endDate).format('dddd, MMMM DD, hh:mm A')}</p>
  <div className='row justify-content-center'>
  <NavLink to="/hackmasterseries" style={{textDecoration:"none"}}><button className='btn teamcreatebtn'>CREATE YOUR TEAM FOR FREE</button></NavLink>


  </div>
  </div>
  </div>
  
  </div>
  </div>
  </div>

  <div className='container'>
  <div className='row mt-5 p-5'>
<h1 className='havocheadabout'>Terms & Conditions</h1>
<div className='row p-4'>

</div>
<div className='row p-5 '>
<ol>
<li className='pricedivlist2'><span className='pricelist2listhead'>Eligibility:</span>Hack Master Series is exclusively open to students of the {event.hostuni} University. Participants must be currently enrolled in an educational institution within the {event.hostuni} University to be eligible.</li>
<li className='pricedivlist2'><span className='pricelist2listhead'>Fair Play: </span>Participants are expected to engage in fair play and maintain ethical standards throughout the competition. Any instances of cheating or unethical behaviour may lead to disqualification.</li>
<li className='pricedivlist2'><span className='pricelist2listhead'>Authorized Systems:</span> Participants are permitted to test their skills and exploit vulnerabilities only on systems explicitly designated for the Hack Master Series event. Unauthorized activities on other systems are strictly prohibited.</li>
<li className='pricedivlist2'><span className='pricelist2listhead'>Data Privacy:</span>Participants must respect the privacy and confidentiality of fellow participants and refrain from sharing sensitive information obtained during the competition.</li>
<li className='pricedivlist2'> <span className='pricelist2listhead'>No Harmful Activities:</span>Engaging in any form of disruptive or harmful activities, including DoS attacks, is strictly prohibited and may lead to immediate disqualification. </li>
<li className='pricedivlist2'> <span className='pricelist2listhead'>Winning Criteria:</span>  First three teams to identify all ten vulnerabilities and submit all ten flags wins the Hack Master Series.  </li>
<li className='pricedivlist2'><span className='pricelist2listhead'>Reporting Vulnerabilities</span> If participants discover any security vulnerabilities outside the CTF scope, they must report them responsibly to the HackersPrey at <b>info@hackersprey.com</b></li>
<li className='pricedivlist2'> <span className='pricelist2listhead'>Team Composition:</span> Each team must consist of a maximum of 5 members. Participants may only be part of one team throughout the competition.</li>
<li className='pricedivlist2'> <span className='pricelist2listhead'>Prize Distribution:</span>  The winners of the Hack Master Series CTF will be rewarded with attractive goodies and gadgets by {event.hostuni} University. </li>
<li className='pricedivlist2'> <span className='pricelist2listhead'>Code of Conduct:</span> Participants are expected to adhere to a high standard of behaviour and respect towards organizers, fellow participants, and event staff.</li>
<li className='pricedivlist2'> <span className='pricelist2listhead'>Liability:</span> The HackersPrey  & {event.hostuni} University holds no responsibility for any technical issues, interruptions, or loss of data during the competition.</li>
<li className='pricedivlist2'> <span className='pricelist2listhead'>Participation Consent:</span>By participating in Hack Master Series CTF, participants consent to the use of their names, images, and submissions for promotional purposes.</li>
<li className='pricedivlist2'> <span className='pricelist2listhead'>Decision Binding:</span>All decisions made by the organizers regarding the CTF, its rules, and any disputes are final and binding.</li>
<li className='pricedivlist2'> <span className='pricelist2listhead'>Amendments:</span>The organizers reserve the right to modify, amend, or cancel any part of the event, including the terms and conditions, without prior notice.</li>
</ol>

</div>


</div>
  </div>
  </div>
    <Footer/>
    </div>
  )
}

export default Havoccommon