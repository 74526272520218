import React from 'react'
import Navbar from './Navbar/Navbar'
import { Outlet } from 'react-router-dom'
import Navbar2 from './Navbar/Navbar2'

const NavbarComponentWrapper = () => {
  return (
    <div>
        <Navbar/>
        

        <Outlet/>
    </div>
  )
}

export default NavbarComponentWrapper
