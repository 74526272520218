import React, { useState, useEffect } from "react";

import { BsFillGridFill, BsListUl } from "react-icons/bs";


import { MdOutlineArrowForwardIos, MdSignalCellularNull } from "react-icons/md";
import axios from "axios";
import { NavLink, useNavigate,useParams,useLocation } from "react-router-dom";


import { faScaleBalanced } from "@fortawesome/free-solid-svg-icons";
const Challengebycategory = (props) => {
  const location=useLocation();
  const [view, setview] = useState("Grid");
  const [slideIndex, setSlideIndex] = useState(2);
  const [challenges, setchallenges] = useState([]);
  const [category, setcategory] = useState([]);
  const [modal, setopenmodal] = useState(false);
  const [completedchallenge, setcompletechallenge] = useState([]);
  let navigate = useNavigate();
  const params=useParams()

  const API_Key = process.env.REACT_APP_API_URL;
  const courseid = location.state.courseid;
  const getchallengecompleted = async () => {
    try {
      const res = await fetch(`${API_Key}/getusercompletedchallenge`, {
        method: "GET",
        headers: {
          mode: "cors",
          "Access-Control-Allow-Origin": `${API_Key}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
        credentials: "include",
      });
      const data = await res.json();
      console.log(data.completedChallenge, "completedchallenge");
      setcompletechallenge(data);

      if (res.status === 200) {
        setopenmodal(false);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getchallengecompleted();
  }, []);
  useEffect(() => {
    setopenmodal(true);
    const fetchchallenges = async () => {
      await axios
        .post(`${API_Key}/gotoCourse/viewChallengesByCategory/`, {
          catid: params.chall_id,
          courseid: courseid,
        })
        .then((res) => {
          setchallenges(res.data.allchallengesData);
          if (res.status === 200) {
            setopenmodal(false);
          }
        })
        .catch((err) => console.log(err));
    };
    fetchchallenges();
  }, []);
  const getcategorydesc = async () => {
    let chall_id = params.chall_id;
    const response = await fetch(`${API_Key}/getcategory/` + chall_id);
    setcategory(await response.json());
  };
  useEffect(() => {
    getcategorydesc();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {modal && (
        <section className="modalBackground3 justify-content-center">
          <div className="container  justify-content-center">
            <div className="row justify-content-center ">
              <img
                src={category.image}
                alt="jhjs"
                className="challengecarimg"
              />
            </div>
          </div>
        </section>
      )}
     
      <div className="Entity_background p-1">
        <div className="Entitycommontopper">
          <div className="container pb-2">
            <div className="row justify-content-center pb-5">
              <div className="col-lg-8 col-md-8 col-12 ">
                <h1 className="Entitycommonhead">
                  {category.title}
                  <span>
                    <hr className="Entitycommonheadhr"></hr>
                  </span>
                </h1>
                <p className="Entitycommonheadpara">{category.desc} </p>
              </div>
              <div className="col-lg-3 col-md-4 col-12   mt-4 mb-0 offset-1">
                <img src={category.image} />
              </div>
            </div>
          </div>
        </div>
        <div className="container ">
          <div className="row float-right">
            <div className="col-lg-10 " id="viewdiv">
              <div className="row justify-content-center ">
                <div className="col-5">
                  <button className="btn" onClick={() => setview("Grid")}>
                    <BsFillGridFill
                      color={view === "Grid" ? "white" : "grey"}
                      size={15}
                    />
                  </button>{" "}
                </div>
                <div className="col-7">
                  {" "}
                  <button className="btn" onClick={() => setview("List")}>
                    <BsListUl
                      color={view === "List" ? "white" : "grey"}
                      size={15}
                    />
                  </button>{" "}
                </div>
              </div>
            </div>
          </div>
          <div className="container mt-5 ">
            {view === "Grid" && (
              <div class="container p-5 mt-5">
                <div class="row mt-5">
                  {challenges.sort((a, b) => {
                    // Custom sorting function based on difficulty levels
                    const difficultyOrder = { '2.0': 0, '3.0': 1, '4.0': 2 };
                    return difficultyOrder[a.timelimit] - difficultyOrder[b.timelimit];
                  })
                    .map((challenge, id) => (
                    <div className="col-lg-3 col-md-3 col-12 mt-5" key={id}>
                      <div className="challengegridcard mt-5">
                        <NavLink
                          to={"/challengedesc/" + challenge._id}
                          style={{ textDecoration: "none" }}
                        >
                          <div className="row justify-content-center p-5">
                            <div className="col-lg-12 " id="cardimgback">
                              <div className=" text-center">
                                <img
                                  src={challenge.cover_image}
                                  style={{ height: "20rem" }}
                                  alt="alt"
                                />
                              </div>
                            </div>
                            <h2 className="text-center cardchalhead">
                              {challenge.title}
                            </h2>
                            {/*<p className='text-center cardchalpara'>{challenge.desc.substr(0,50)}</p>*/}
                          </div>
                        </NavLink>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {view === "List" && (
              <div class="container p-5 mt-5">
                <div class="row mt-5">
                  {challenges
                    .sort((a, b) => {
                      // Custom sorting function based on difficulty levels
                      const difficultyOrder = { "2.0": 0, "3.0": 1, "4.0": 2 };
                      return (
                        difficultyOrder[a.timelimit] -
                        difficultyOrder[b.timelimit]
                      );
                    })
                    .map((challenges, id) => (
                      <div className="col-lg-6 col-md-6 col-12 p-5" key={id}>
                        <NavLink
                          to={"/challengedesc/" + challenges._id}
                          style={{ textDecoration: "none" }}
                        >
                          {" "}
                          <div className=" row challengelistcard p-5 ">
                            <div className="col-lg-2 col-md-10 col">
                              <div className="lablogodivh">
                                <img
                                  src={challenges.cover_image}
                                  alt="logo"
                                  style={{
                                    width: "13rem",
                                    transform: "scale(1.9)",
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-lg-8 col-md-8 col-8 pt-3">
                              <h2 className="challisthead">
                                {challenges.title}
                              </h2>
                            </div>
                            <div className="col-lg-1 col pt-3">
                              <div className="Labbtn">
                                <MdOutlineArrowForwardIos
                                  className="span"
                                  color="white"
                                  size={8}
                                  style={{ marginTop: "-2.4rem" }}
                                />
                              </div>
                            </div>
                          </div>
                        </NavLink>{" "}
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Challengebycategory;
