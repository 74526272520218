import React, { useState, useEffect } from "react";
import "./Setting.css";
import Notification from "../../../Assets/Dashboard/Notifications.svg";
import General from "../../../Assets/Settings/Group_400.svg";
import Network from "../../../Assets/Dashboard/Network.svg";
import axios from "axios";
import Profileupdated from "../Modals/Profileupdated";
import Wentwrong from "../Modals/Wentwrong";
import { useNavigate } from "react-router-dom";
import Loading from "../../Dashboard/Modals/Loading";
import GeneralComponent from "./GeneralComponent";
import NotificationComponent from "./NotificationComponent";
import NetworkComponent from "./NetworkComponent";

const Setting = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [modalOpen6, setModalOpen6] = useState(false);
  const [usedetail, setuserdetail] = useState("");
  const [item, setItem] = useState("C");
  const [file, setfile] = useState("");
 const [instance, setinstance] = useState("");
 const [rerender,setRerender]=useState(false);
  const [scrf, setscrf] = useState("");
  useEffect(() => {
    const getCsrfToken = async () => {
      const { data } = await axios.get(`${API_Key}/auth/csrf-token`, {
        withCredentials: true,
      });
      axios.defaults.headers.post["X-CSRF-Token"] = data.csrfToken;

      setscrf(data.csrfToken);
    };
    getCsrfToken();
  }, []);
  const API_Key = process.env.REACT_APP_API_URL;
  const fetchuser = async () => {
    try {
      const res = await fetch(`${API_Key}/Userloggedin`, {
        method: "GET",
        headers: {
          mode: "cors",
          "Access-Control-Allow-Origin": `${API_Key}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
        credentials: "include",
      });
      const data = await res.json();

      setuserdetail(data);
      setinstance(data?.instance[0]?.state);

      if (res.status !== 200) {
        const error = new Error(res.error);
        throw error;
      }
    } catch (err) {
      console.log(err);
      //navigate("/signin");
    }
  };
  useEffect(() => {
  
    fetchuser();
  }, [rerender]);
  const fetchusercourse = async () => {
    try {
      const res = await fetch(`${API_Key}/getcourse_details`, {
        method: "GET",
        headers: {
          mode: "cors",
          "Access-Control-Allow-Origin": `${API_Key}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
        credentials: "include",
      });
      const data = await res.json();

      setuserdetail(data);

      setfile(data.allcourseData[0].score);

      if (res.status !== 200) {
        const error = new Error(res.error);
        throw error;
      }
    } catch (err) {
      console.log(err);
      //navigate("/signin");
    }
  };
  useEffect(() => {
    fetchusercourse();
  }, [rerender]);

  return (
    <div>
      {modalOpen && <Profileupdated setOpenModal={setModalOpen} />}
      {modalOpen2 && <Wentwrong setOpenModal={setModalOpen2} />}

      {modalOpen6 && <Loading setOpenModal={setModalOpen6} />}
      

      <div className="background_dashboard">
        <div className="row justify-content-center">
          <h1 className="commonhead">
            Settings
            <div className="row justify-content-center">
              <hr className="commonheadhr"></hr>
            </div>
          </h1>
        </div>
        <div className="container-fluid">
          <div className="row p-5">
            <div className="col-lg-3 justify-content-center ">
              <div
                className="row mt-5 justify-content-center pt-0 mb-0"
                onClick={() => setItem("A")}
                style={{ cursor: "pointer" }}
              >
                <div
                  className={
                    item === "A"
                      ? "col-lg-6 col-md-6 pt-5 pb-0 justify-content-center settingdivchange"
                      : "col-lg-6 pt-5 pb-0 pr-5 pl-5 justify-content-center settingdiv1"
                  }
                >
                  <img
                    src={General}
                    style={{
                      height: "6rem",
                      width: "6rem",
                    }}
                    className={item === "A" ? "genraldiv2" : "genraldiv"}
                    alt="Genral"
                  />

                  <h2 className="settinghead mb-2 pt-2 pb-5 mt-3 ">General</h2>
                </div>
              </div>
              <div
                className="row mt-5 justify-content-center p-0"
                onClick={() => setItem("B")}
                style={{ cursor: "pointer" }}
              >
                <div
                  className={
                    item === "B"
                      ? "col-lg-6 pt-5 pb-0 pr-5 pl-5 justify-content-center settingdivchange"
                      : "col-lg-6 pt-5 pb-0 pr-5 pl-5 justify-content-center settingdiv1"
                  }
                >
                  <img
                    src={Notification}
                    style={{
                      height: "6rem",
                      width: "6rem",
                    }}
                    className={item === "B" ? "genraldiv2" : "genraldiv"}
                    alt="Genral"
                  />

                  <h2 className=" settinghead mb-2 pt-2 pb-5 mt-3">
                    Notifications
                  </h2>
                </div>
              </div>
              <div
                className="row mt-5 justify-content-center p-0 "
                onClick={() => setItem("C")}
                style={{ cursor: "pointer" }}
              >
                <div
                  className={
                    item === "C"
                      ? "col-lg-6 pt-5 pb-0 pr-5 pl-5 justify-content-center settingdivchange"
                      : "col-lg-6 pt-5 pb-0 pr-5 pl-5 justify-content-center settingdiv1"
                  }
                >
                  <img
                    src={Network}
                    style={{
                      height: "6rem",
                      width: "6rem",
                    }}
                    className={item === "C" ? "genraldiv2" : "genraldiv"}
                    alt="Genral"
                  />

                  <h2
                    className="settinghead mb-2 pt-2 pb-5 mt-3"
                    onClick={() => setItem("C")}
                  >
                    Networks
                  </h2>
                </div>
              </div>
            </div>

            <div className="col-lg-9 settingdiv2 mt-5 p-5">
              {item === "A" && <GeneralComponent usedetail={usedetail}/>}

              {item === "B" && (<NotificationComponent/>
              )}
              {item === "C" && 
              <NetworkComponent setRerenderP={()=>setRerender(!rerender)} usedetail={usedetail} file={file} setfile={setfile} scrf={scrf} instance={instance} setinstance={setinstance}/>
              }
              </div>
             
              </div></div>
              </div>
              </div>
            )
          }
          
          export default    (Setting)